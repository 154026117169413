import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from "ngrx-json-api";
import {Theme, ThemeColor} from "../model/theme";

@Pipe({
  name: 'resourceToTheme'
})
export class ResourceToThemePipe implements PipeTransform {

  transform(resource: Resource): Theme {

    if (resource?.relationships?.field_colors?.reference) {

      const {id, type} = resource;

      const colorResources: Resource[] = resource?.relationships?.field_colors?.reference;

      const colors: { [name: string]: ThemeColor } = {};
      colorResources.forEach(color => {
        const colorAttributes: any = color.attributes;
        const fieldColor: any = colorAttributes.field_color;
        colors[colorAttributes.field_identifier] = {
          color: fieldColor.color,
          opacity: fieldColor.opacity
        };
      });

      const theme: Theme = {
        id,
        type,
        colors
      };

      return theme;

    }

    return null;

  }

}
