import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromSnipcart from './snipcart.reducer';
import {Cart, SnipcartSession} from "./snipcart.model";

export const selectSnipcartState = createFeatureSelector<fromSnipcart.State>(
  fromSnipcart.snipcartFeatureKey
);


export const isStateCartOpen = createSelector(
  selectSnipcartState,
  (state: fromSnipcart.State) => state.cartOpenClose === 'open'
);

export const isStateCart = createSelector(
  selectSnipcartState,
  (state: fromSnipcart.State) => state.cartState === 'cart'
);

export const isStateCheckout = createSelector(
  selectSnipcartState,
  (state: fromSnipcart.State) => state.cartState === 'checkout'
);

export const getApiKey = createSelector(
  selectSnipcartState,
  (state: fromSnipcart.State) => state.apiKey
);

export const getCartSession = createSelector(
  selectSnipcartState,
  (state: fromSnipcart.State) => state.cartSession
);

export const getCartSessionCart = createSelector(
  getCartSession,
  (snipcartSession: SnipcartSession) => snipcartSession?.cart
);

export const getBillingAddress = createSelector(
  getCartSessionCart,
  (cart: Cart) => cart?.billingAddress
);

export const getUserMail = createSelector(
  getCartSessionCart,
  (cart: Cart) => cart?.email
);

export const getCurrency = createSelector(
  getCartSessionCart,
  (cart: Cart) => cart?.currency
);

export const getTotal = createSelector(
  getCartSessionCart,
  (cart: Cart) => cart?.total
);

export const getCartSettings = createSelector(
  selectSnipcartState,
  (state: fromSnipcart.State) => state.settings
);

export const onlyAllowGuests = createSelector(
  getCartSettings,
  (settings: any) => settings?.onlyAllowGuests
);
