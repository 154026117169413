import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CART_MESSAGE} from '../../providers/storeFront.providers';
import {Observable} from 'rxjs';
import {CartMessageService} from './cart-message.service';

@Component({
  selector: 'app-cart-message',
  templateUrl: './cart-message.component.html',
  styleUrls: ['./cart-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CartMessageComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(CART_MESSAGE) public cartMessage$: Observable<string>,
    private cartMessageService: CartMessageService
  ) {
    cartMessageService.componentAdded = true;
    cartMessageService.componentRemoved = false;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.cartMessageService.componentAdded = false;
    this.cartMessageService.componentRemoved = true;
  }

}

@NgModule({
  declarations: [
    CartMessageComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [CartMessageComponent]
})

export class CartMessageModule {

  constructor() {
  }

}

