<ng-template ngFor [ngForOf]="alerts$|async" let-alert>

  <ng-template [ngTemplateOutlet]="alertTemplate"
               [ngTemplateOutletContext]="{alert: alert}">
  </ng-template>

</ng-template>

<ng-template #alertTemplate let-alert="alert">

  <div class="alert-container" [@alertAnimation]="(alertsVisible$|async)===true || alert.closeable !== true?'show':'hide'" >

    <div class="alert-group p-3" [ngClass]="alert.level">

      <div class="alert-content-group">

        <ng-container *ngIf="alert.title">
          <h3 [innerHTML]="alert.title"></h3>
        </ng-container>

        <div appDrupalRichTextLinks [innerHTML]="alert.description"></div>

      </div>

      <ng-container *ngIf="alert.closeable">

        <button #closeButton mat-icon-button class="close-button" (click)="onRemoveAlert(alert)">
          <mat-icon svgIcon="icon-close"></mat-icon>
        </button>

      </ng-container>

    </div>

  </div>

</ng-template>
