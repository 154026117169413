import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Category} from '../../model/product-category';
import {ResourceIdentifier} from '@madeinlune/ngrx-json-api';


export const loadCatalogCategories = createAction(
  '[CatalogCategory/API] Load CatalogCategories',
  props<{ categories: Category[] }>()
);

export const processCategories = createAction(
  '[CatalogCategory/API] Process CatalogCategories',
  props<{ categories: Category[] }>()
);

export const addCatalogCategory = createAction(
  '[CatalogCategory/API] Add CatalogCategory',
  props<{ category: Category }>()
);

export const upsertCatalogCategory = createAction(
  '[CatalogCategory/API] Upsert CatalogCategory',
  props<{ category: Category }>()
);

export const addCatalogCategories = createAction(
  '[CatalogCategory/API] Add CatalogCategories',
  props<{ categories: Category[] }>()
);

export const upsertCatalogCategories = createAction(
  '[CatalogCategory/API] Upsert CatalogCategories',
  props<{ categories: Category[] }>()
);

export const updateCatalogCategory = createAction(
  '[CatalogCategory/API] Update CatalogCategory',
  props<{ category: Update<Category> }>()
);

export const updateCatalogCategories = createAction(
  '[CatalogCategory/API] Update CatalogCategories',
  props<{ categories: Update<Category>[] }>()
);

export const deleteCatalogCategory = createAction(
  '[CatalogCategory/API] Delete CatalogCategory',
  props<{ id: string }>()
);

export const deleteCatalogCategories = createAction(
  '[CatalogCategory/API] Delete CatalogCategories',
  props<{ ids: string[] }>()
);

export const clearCatalogCategories = createAction(
  '[CatalogCategory/API] Clear CatalogCategories'
);

export const addOrUpdateCategories = createAction(
  '[CatalogCategory/API] Add or Update CatalogCategories',
  props<{ categories: Category[] }>()
);

export const updateCategoryProducts = createAction(
  '[CatalogCategory/API] Add or Update Category Products',
  props<{ categoryId: string, products: ResourceIdentifier[] }>()
);

