import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';
import {ParagraphImages} from '../model/paragraph-images';
import {Image, ImageProfile} from '../model/image';

@Pipe({
  name: 'resourceToParagraphImages'
})
export class ResourceToParagraphImagesPipe implements PipeTransform {

  transform(resource: Resource): ParagraphImages {

    if (resource) {
      const {id, type} = resource;
      const title: string = resource.attributes.field_header_title;
      const fillMode: string = resource.attributes.field_image_fill_mode;
      const ratio: string = resource.attributes.field_display_ratio;
      const mediaImages: Resource[] = resource.relationships?.field_images?.reference;

      let images: Image[];
      if (mediaImages) {
        images = mediaImages.map(image => this.buildImage(image?.relationships?.field_media_image?.data));
      } else {
        images = [];
      }

      const paragraphImages: ParagraphImages = {
        id,
        type,
        title,
        images,
        fillMode,
        ratio
      };
      return paragraphImages;

    }

    return null;

  }

  buildImage(resource: Resource): Image {

    const {id, type} = resource;

    const profiles: { [profileId: string]: ImageProfile } = {};

    const links: any = resource.meta.imageDerivatives.links;

    Object.keys(links).forEach(profileKey => {
      if (profileKey !== 'self') {
        const rawProfile: any = links[profileKey];
        profiles[profileKey] = {
          href: rawProfile.href,
          meta: {
            width: rawProfile.meta.width,
            height: rawProfile.meta.height
          }
        };
      }
    });

    const image: Image = {
      id,
      type,
      profiles
    };

    return image;
  }

}
