import {Injectable} from '@angular/core';
import * as fromWindow from './window.reducer';
import {Store} from '@ngrx/store';
import {openBottomSheet, openWindow} from './window.actions';
import {BottomSheetOptions, DialogOptions, WindowAction} from './window.model';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor(
    private windowStore: Store<fromWindow.State>
  ) {
  }

  openBottomSheet(options: BottomSheetOptions, closePath: string = null) {
    this.windowStore.dispatch(openBottomSheet({options, closePath}));
  }

  openWindow(options: DialogOptions = null, closePath: string = null) {
    this.windowStore.dispatch(openWindow({options, closePath}));
  }

  openAlert(
    title: string,
    message: string,
    actions: WindowAction[] = null,
    contentClasses: string[] = []
  ) {
    this.openWindow({
      dialogConfig: {
        width: '400px',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: ['alert-window'],
        backdropClass: ['alert-backdrop'],
        disableClose: true
      },
      passport: {
        title,
        message,
        actions,
        barColor: 'primary',
        showCloseButton: false,
        contentClasses
      }
    });
  }

}
