import {Action, createReducer, on} from '@ngrx/store';
import * as WindowActions from './window.actions';

export const windowFeatureKey = 'window';

export interface State {
  openIds: string[];
}

export const initialState: State = {
  openIds: []
};


const windowReducer = createReducer(
  initialState,
  on(WindowActions.addWindowId, (state, action) => {
    const hasNotWindowId: boolean = state.openIds.indexOf(action.windowId) === -1;
    return {
      ...state,
      openIds: hasNotWindowId ? [...state.openIds, action.windowId] : state.openIds
    };
  }),
  on(WindowActions.removeWindowId, (state, action) => {
    return {
      ...state,
      openIds: state.openIds.filter(id => id !== action.windowId)
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return windowReducer(state, action);
}

