import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';
import {DrupalBody} from '../model/drupal-body';
import {ParagraphNewsletter} from '../model/paragraph-newsletter';

@Pipe({
  name: 'resourceToParagraphNewsletter'
})
export class ResourceToParagraphNewsletterPipe implements PipeTransform {

  transform(resource: Resource): ParagraphNewsletter {
    if (resource) {
      const {id, type} = resource;
      const title: string = resource.attributes.title;
      const body: DrupalBody = resource.attributes.body;
      const audienceId: string = resource.attributes.field_newsletter_audience;
      const paragraphNewsletter: ParagraphNewsletter = {id, type, title, body, audienceId};
      return paragraphNewsletter;
    }

    return null;
  }

}
