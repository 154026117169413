import {Pipe, PipeTransform} from '@angular/core';
import {LatinisePipe, SlugifyPipe} from 'ngx-pipes';

@Pipe({
  name: 'titleToPath'
})
export class TitleToPathPipe implements PipeTransform {

  constructor(
    private latinisePipe: LatinisePipe,
    private slugifyPipe: SlugifyPipe
  ) {

  }

  transform(title: string, language: string = null): string {
    if (title || title === '') {
      const latinised: string = this.latinisePipe.transform(title);
      if (language) {
        return '/' + language + '/' + this.slugifyPipe.transform(latinised);
      } else {
        return this.slugifyPipe.transform(latinised);
      }
    }else{
      return null;
    }
  }

}
