import {Action, createReducer, on} from '@ngrx/store';
import * as SnipcartActions from './snipcart.actions';
import {SnipcartSession} from './snipcart.model';

export const snipcartFeatureKey = 'snipcart';

export type CartOpenClose = 'open' | 'closed';

export type CartState = 'cart' | 'checkout';

export interface State {
  cartOpenClose: CartOpenClose;
  cartState: CartState;
  cartSession: SnipcartSession;
  settings: any;
  apiKey: string;
}

export const initialState: State = {
  cartOpenClose: 'closed',
  cartState: 'cart',
  cartSession: null,
  settings: null,
  apiKey: null
};


const snipcartReducer = createReducer(
  initialState,

  on(SnipcartActions.openCart, state => {
    return {
      ...state,
      cartOpenClose: 'open'
    };
  }),
  on(SnipcartActions.closeCart, state => {
    return {
      ...state,
      cartOpenClose: 'closed'
    };
  }),
  on(SnipcartActions.setCartState, (state, {cartState}) => {
    return {
      ...state,
      cartState
    };
  }),
  on(SnipcartActions.setCartSession, (state, {cartSession}) => {
    return {
      ...state,
      cartSession
    };
  }),
  on(SnipcartActions.setPublicApiKey, (state, {apiKey}) => {
    return {
      ...state,
      apiKey
    };
  }),
  on(SnipcartActions.setSettings, (state, {settings}) => {
    return {
      ...state,
      settings
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return snipcartReducer(state, action);
}

