import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromAnalytics from './analytics.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AnalyticsEffects} from './analytics.effects';
import {RouterlessTracking} from 'angulartics2';
import {Angulartics2RouterlessModule} from 'angulartics2/routerlessmodule';
import {AnalyticsTracking} from './analytics.tracking';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAnalytics.analyticsFeatureKey, fromAnalytics.reducer),
    EffectsModule.forFeature([AnalyticsEffects]),
    Angulartics2RouterlessModule.forRoot(),
  ],
  providers: [
    {provide: RouterlessTracking, useClass: AnalyticsTracking},
  ]
})
export class AnalyticsModule {
}
