import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAlerts from './alerts.reducer';

export const selectAlertsState = createFeatureSelector<fromAlerts.State>(
  fromAlerts.alertsFeatureKey
);

export const getAlerts = createSelector(
  selectAlertsState,
  (state: fromAlerts.State) => state?.alerts
);

export const alertsVisible = createSelector(
  selectAlertsState,
  (state: fromAlerts.State) => state?.visible
);
