import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentGeneratorDirective} from './component-generator.directive';

@NgModule({
  declarations: [ComponentGeneratorDirective],
  imports: [
    CommonModule
  ],
  exports: [
    ComponentGeneratorDirective
  ],
  providers: []
})
export class CoreUtilsModule {

}
