import {Pipe, PipeTransform} from '@angular/core';
import {Product, Variant} from '../model/product';
import {OrderByPipe, TrurthifyPipe} from 'ngx-pipes';

export interface MinMaxPrice {

  min: string;
  max: string;
  currency: string;

}

@Pipe({
  name: 'productMinMaxPrice'
})
export class ProductMinMaxPricePipe implements PipeTransform {

  constructor(
    private trurthifyPipe: TrurthifyPipe,
    private orderByPipe: OrderByPipe
  ) {
  }

  transform(product: Product): MinMaxPrice {
    const orderedByPriceVariant: Variant[] = this.orderByPipe.transform(
      this.trurthifyPipe.transform(product.variants),
      'price.number'
    );

    const minMaxPrice: MinMaxPrice = {
      min: [...orderedByPriceVariant].shift()?.price?.number?.toFixed(2),
      max: [...orderedByPriceVariant].pop()?.price?.number?.toFixed(2),
      currency: [...orderedByPriceVariant].shift()?.price?.currency_code
    };

    return minMaxPrice;
  }

}
