import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class CommerceCartTokenInterceptor implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) protected platformId) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(isPlatformBrowser(this.platformId)) {
      const cartToken = localStorage.getItem('cart_token');

      if (request.url.indexOf('jsonapi') && cartToken && !request.headers.get('Commerce-Cart-Token')) {
        request = request.clone({
          setHeaders: {
            'Commerce-Cart-Token': `${cartToken}`
          }
        });
      }
    }

    return next.handle(request);
  }
}
