import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Product} from '../../model/product';
import {Resource} from '@madeinlune/ngrx-json-api/src/interfaces';
import {ProductsGroup} from './catalog-product.reducer';

export const loadCatalogProducts = createAction(
  '[CatalogProduct/API] Load CatalogProducts',
  props<{ products: Product[] }>()
);

export const addCatalogProduct = createAction(
  '[CatalogProduct/API] Add CatalogProduct',
  props<{ product: Product }>()
);

export const upsertCatalogProduct = createAction(
  '[CatalogProduct/API] Upsert CatalogProduct',
  props<{ product: Product }>()
);

export const addCatalogProducts = createAction(
  '[CatalogProduct/API] Add CatalogProducts',
  props<{ products: Product[] }>()
);

export const upsertCatalogProducts = createAction(
  '[CatalogProduct/API] Upsert CatalogProducts',
  props<{ products: Product[] }>()
);

export const updateCatalogProduct = createAction(
  '[CatalogProduct/API] Update CatalogProduct',
  props<{ product: Update<Product> }>()
);

export const updateCatalogProducts = createAction(
  '[CatalogProduct/API] Update CatalogProducts',
  props<{ products: Update<Product>[] }>()
);

export const deleteCatalogProduct = createAction(
  '[CatalogProduct/API] Delete CatalogProduct',
  props<{ id: string }>()
);

export const deleteCatalogProducts = createAction(
  '[CatalogProduct/API] Delete CatalogProducts',
  props<{ ids: string[] }>()
);

export const clearCatalogProducts = createAction(
  '[CatalogProduct/API] Clear CatalogProducts'
);

export const setProductsGroup = createAction(
  '[CatalogProduct/API] Set Products Group',
  props<{ productsGroup: ProductsGroup }>()
);

export const clearProductsGroup = createAction(
  '[CatalogProduct/API] Clear Products Group'
);


export const parseAndAddOrUpdateProducts = createAction(
  '[CatalogProduct/API] Parse and add or update Products',
  props<{ products: Resource[] }>()
);
