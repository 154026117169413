import {NgModule} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {APP_CONFIG_PROVIDERS} from './app-config.providers';

@NgModule({
  providers: [
    AppConfigService,
    APP_CONFIG_PROVIDERS
  ],
  imports: []
})
export class AppConfigModule {
}
