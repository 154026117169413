import {createAction, props} from '@ngrx/store';
import {CartMode} from '../../../model/cart-modes';
import {Query, Resource, StoreResource} from 'ngrx-json-api';
import {Category, ProductCategory} from '../../../model/product-category';
import {StoreFront} from '../../../model/store-front';
import {StorePage} from '../../../model/store-page';
import {Product} from '../../../model/product';
import {PathInfos} from '../../../model/path-infos';
import {CategoryMenu} from '../../../model/category-menu';
import {DrupalNode} from '../../../model/drupal-node';
import {ResourceIdentifier} from '@madeinlune/ngrx-json-api/src/interfaces';

export const initializeLuneShop = createAction(
  '[LuneShop] Initialize'
);

export const initializeStateFromScully = createAction(
  '[LuneShop] Initialize State from Scully'
);

export const initializeSnipcart = createAction(
  '[LuneShop] Initialize Snipcart',
  props<{ storeFront: StoreFront }>()
);

export const initializeDrupalCommerce = createAction(
  '[LuneShop] Initialize Drupal Commerce',
  props<{ storeFront: StoreFront }>()
);

export const setAppIcons = createAction(
  '[LuneShop] Set App Icons'
);

export const loadAccountNode = createAction(
  '[LuneShop] Load Account Node'
);

export const loadStoreFrontNode = createAction(
  '[LuneShop] Load StoreFront Node'
);

export const processStoreFrontNode = createAction(
  '[LuneShop] Process StoreFront Node',
  props<{ storeFront: StoreFront }>()
);


export const setCartMode = createAction(
  '[LuneShop] Set CartMode',
  props<{ cartMode: CartMode }>()
);

export const setStoreFront = createAction(
  '[LuneShop] Set StoreFront',
  props<{ storeFront: StoreFront }>()
);

export const setPages = createAction(
  '[LuneShop] Set Pages',
  props<{ pages: StorePage[] }>()
);

export const updateContentInViewport = createAction(
  '[LuneShop] Update content in Viewport',
  props<{ content: DrupalNode }>()
);

export const setDirectAccessPath = createAction(
  '[LuneShop] Set Direct Access Path',
  props<{ path: string }>()
);

export const setDirectAccessResource = createAction(
  '[LuneShop] Set Direct Access Resource',
  props<{ resource: ProductCategory | Product }>()
);

export const setDirectAccessResourceReady = createAction(
  '[LuneShop] Set Direct Access Resource Ready',
  props<{ ready: boolean }>()
);

export const setCategoriesLoadingQueue = createAction(
  '[LuneShop] Set Categories Loading Queue',
  props<{ categories: ResourceIdentifier[] }>()
);

export const moveToLoadingQueueTop = createAction(
  '[LuneShop] Move To Top Category Loading Queue',
  props<{ productCategory: ProductCategory }>()
);

export const setCategories = createAction(
  '[LuneShop] Set Categories',
  props<{ categories: Category[] }>()
);

export const loadGroupAccountSuccess = createAction(
  '[LuneShop] Set Group Account',
  props<{ groupAccount: Resource }>()
);

export const setError = createAction(
  '[LuneShop] Error',
  props<{ error: any }>()
);

export const loadCategoryProducts = createAction(
  '[LuneShop] Load Products of Category',
  props<{ productCategory: ProductCategory }>()
);

export const loadCategoryProductsSuccess = createAction(
  '[LuneShop] Load Success Products of Category',
  props<{ productCategory: ProductCategory }>()
);

export const loadCategories = createAction(
  '[LuneShop] Load Categories'
);

export const loadCategoriesSuccess = createAction(
  '[LuneShop] Load Categories Success'
);

export const loadCategoriesChunk = createAction(
  '[LuneShop] Load Categories Chunk',
  props<{
    query: Query,
    offset: number
  }>()
);

export const loadCategoriesChunkSuccess = createAction(
  '[LuneShop] Load Categories Chunk Success',
  props<{
    offset: number,
    query: Query,
    needMore: boolean,
    resourcesLength: number
  }>()
);

export const loadCategoryProductsChunks = createAction(
  '[LuneShop] Load Category Products Chunks',
  props<{ productCategory: ProductCategory, offset: number }>()
);

export const loadCategoryProductsChunksSuccess = createAction(
  '[LuneShop] Load Category Products Chunks Success',
  props<{
    productCategory: ProductCategory,
    products: Product[],
    offset: number,
    needMore: boolean
  }>()
);

export const loadInformationsNodes = createAction(
  '[LuneShop] Load Informations Nodes'
);

export const loadMessageNodes = createAction(
  '[LuneShop] Load Messages Nodes'
);

export const addToHistory = createAction(
  '[LuneShop] Add to History',
  props<{ navigation: any }>()
);

export const setPathInfos = createAction(
  '[LuneShop] Set Path Infos',
  props<{ pathInfos: PathInfos[] }>()
);

export const addProductToMap = createAction(
  '[LuneShop] Add Product to Map',
  props<{ product: Product }>()
);

export const setCategoryMenu = createAction(
  '[LuneShop] Set Category Menu',
  props<{ categoryMenu: CategoryMenu }>()
);

export const parsePages = createAction(
  '[LuneShop] Parse Pages',
  props<{ pages: StoreResource[] }>()
);
