import {Inject, Injectable} from '@angular/core';
import {DrupalNode} from './model/drupal-node';
import {ResourceTypes} from './ngrx-json-api/ngrx-json-api-definitions';
import {PathInfos} from './model/path-infos';
import {WINDOW} from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root'
})
export class UrlsService {

  angularRoutesToContentPaths: { [angularRoute: string]: string } = {};

  contentPathsToAngularRoutes: { [contentPath: string]: string } = {};

  angularRoutesToResourceIdentifier: { [angularRoute: string]: DrupalNode } = {};

  contentPathsToResourceIdentifier: { [contentPath: string]: DrupalNode } = {};

  constructor(
    @Inject(WINDOW) private window: Window
  ) {
  }

  public addUrlInfos(resources: PathInfos[], routeSingleCategory: boolean): void {

    const urlMap: { [contentType: string]: string } = {
      [ResourceTypes.productDefault]: 'products',
      [ResourceTypes.storeFront]: 'home',
      [ResourceTypes.storePage]: 'pages',
      [ResourceTypes.storeSection]: 'categories'
    };

    const origin: string = this.window.location.origin;

    if (Array.isArray(resources)) {
      resources.forEach(item => {

        if (urlMap[item.type]) {
          let angularPath: string;
          let contentPath: string;

          // console.log('(item as DrupalNode).path', (item as DrupalNode).path);

          if (!(item as DrupalNode).path || (item as DrupalNode).path === '') {
            // console.warn('no path :', item);
            // return;
          }
          const typeUrl = urlMap[item.type];

          if (item.type === ResourceTypes.storeSection) {

            // We need to manage two urls and two routing strategies for sections, one for mobile and Scully, the other for desktop

            if (!routeSingleCategory) {
              const url: URL = new URL(origin + this.contentPathsToAngularRoutes['/fr']);
              angularPath = url.pathname + '#section--' + item.id;
              // console.log('angularPath', angularPath);
            } else {
              angularPath = angularPath = '/' + typeUrl + '/' + item.id;
            }

            contentPath = '/' + item.language + (item as DrupalNode).path;

            /*this.angularRoutesToContentPaths[angularPath] = contentPath;
            this.contentPathsToAngularRoutes[contentPath] = angularPath;
            this.angularRoutesToResourceIdentifier[angularPath] = item;
            this.contentPathsToResourceIdentifier[contentPath] = item;*/

          } else if (item.type === ResourceTypes.storeFront) {

            angularPath = '/' + typeUrl + '/' + item.id + '#appWelcome';
            contentPath = '/' + item.language + (item as DrupalNode).path;

            // console.log('storeFront angularPath', angularPath);
            // console.log('storeFront contentPath', contentPath);

          } else {

            angularPath = '/' + typeUrl + '/' + item.id;
            contentPath = '/' + item.language + (item as DrupalNode).path;
            /*console.log({angularPath});
            console.log({contentPath});*/

          }

          this.angularRoutesToContentPaths[angularPath] = contentPath;
          this.contentPathsToAngularRoutes[contentPath] = angularPath;

          this.angularRoutesToResourceIdentifier[angularPath] = item;
          this.contentPathsToResourceIdentifier[contentPath] = item;

          // this.angularRoutesToContentPaths[angularPath] = contentPath + '#/cart';
          this.contentPathsToAngularRoutes[contentPath + '#/cart'] = angularPath;
          this.contentPathsToAngularRoutes[contentPath + '#/signin'] = angularPath;
          this.contentPathsToAngularRoutes[contentPath + '#/checkout'] = angularPath;
          this.contentPathsToAngularRoutes[contentPath + '#/dashboard'] = angularPath;
          this.contentPathsToAngularRoutes[contentPath + '#/cart/'] = angularPath;
          this.contentPathsToAngularRoutes[contentPath + '#/signin/'] = angularPath;
          this.contentPathsToAngularRoutes[contentPath + '#/checkout/'] = angularPath;
          this.contentPathsToAngularRoutes[contentPath + '#/dashboard/'] = angularPath;

          if (item.type === ResourceTypes.storeFront) {
            // console.log('ResourceTypes.storeFront' + contentPath + '#/cart');
          }

          if (item.type === ResourceTypes.storePage) {
            // console.log('ResourceTypes.storePage contentPath', contentPath);
            // console.log('ResourceTypes.storePage angularPath', angularPath);
          }

          // this.angularRoutesToResourceIdentifier[angularPath + '&snipcartStep=cart'] = item;
          this.contentPathsToResourceIdentifier[contentPath + '#/cart'] = item;
          this.contentPathsToResourceIdentifier[contentPath + '#/signin'] = item;
          this.contentPathsToResourceIdentifier[contentPath + '#/checkout'] = item;
          this.contentPathsToResourceIdentifier[contentPath + '#/dashboard'] = item;
          this.contentPathsToResourceIdentifier[contentPath + '#/cart/'] = item;
          this.contentPathsToResourceIdentifier[contentPath + '#/signin/'] = item;
          this.contentPathsToResourceIdentifier[contentPath + '#/checkout/'] = item;
          this.contentPathsToResourceIdentifier[contentPath + '#/dashboard/'] = item;

          if (item.type === ResourceTypes.storeSection) {
          /*console.log('/   /   /   /   /   /   /');
          console.log('item', item);
          console.log('this.angularRoutesToContentPaths', this.angularRoutesToContentPaths);
          console.log('this.contentPathsToAngularRoutes', this.contentPathsToAngularRoutes);
          console.log('this.angularRoutesToResourceIdentifier', this.angularRoutesToResourceIdentifier);
          console.log('this.contentPathsToResourceIdentifier', this.contentPathsToResourceIdentifier);
          console.log('/   /   /   /   /   /   /');*/
          }
        }
      });
    }
  }

}
