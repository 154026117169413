import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';
import {DelayedOrder, SocialNetwork, StoreFront} from '../model/store-front';
import {CartModes} from '../model/cart-modes';
import {ResourceToImagePipe} from './resource-to-image.pipe';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ResourceToThemePipe} from './resource-to-theme.pipe';
import {AppIcons} from '../model/app-icons';
import {ShortenPipe, StripTagsPipe} from 'ngx-pipes';
import {TitleToPathPipe} from './title-to-path.pipe';
import {AppConfigService} from '../config/app-config.service';
import {ResourceToStorePagePipe} from './resource-to-store-page.pipe';

@Pipe({
  name: 'resourceToStoreFront'
})
export class ResourceToStoreFrontPipe implements PipeTransform {

  constructor(
    private resourceToImagePipe: ResourceToImagePipe,
    private resourceToThemePipe: ResourceToThemePipe,
    private stripTagsPipe: StripTagsPipe,
    private shortenPipe: ShortenPipe,
    private titleToPathPipe: TitleToPathPipe,
    private resourceToStorePagePipe: ResourceToStorePagePipe,
    protected appConfig: AppConfigService,
  ) {
  }

  transform(resource: Resource): Observable<StoreFront> {

    if (resource) {

      return combineLatest([
        this.resourceToImagePipe
          .transform(resource?.relationships?.field_logo?.reference?.relationships?.field_media_image.data),
        this.resourceToImagePipe
          .transform(resource.relationships.field_default_product_image.reference?.relationships.field_media_image.data),
        this.resourceToImagePipe
          .transform(resource.relationships.field_image.reference?.relationships.field_media_image.data),
        this.resourceToImagePipe
          .transform(resource.relationships.field_icon.reference?.relationships.field_media_image.data),
        this.resourceToStorePagePipe
          .transform(resource.relationships.field_home_page.reference),
      ]).pipe(
        map(([logo, defaultProductImage, welcomeImage, iconImage, homePage]) => {

          const rawIcons: any = resource?.relationships?.field_icon?.reference?.relationships?.field_media_image?.data;
          let appIcons: AppIcons;
          if (rawIcons) {
            appIcons = {};
            const profiles: any = rawIcons.meta.imageDerivatives.links;
            if (profiles) {
              ['16', '32', '180'].forEach(iconSize => {
                  const profile: any = profiles['icon_' + iconSize];
                  if (profile) {
                    const size: number = profile.meta?.width;
                    const href: string = profile.href;
                    appIcons[size] = {
                      size,
                      href,
                      mimeType: 'image/png'
                    };
                  }
                }
              );
            }
          }

          const descriptionPlainText: string = resource?.attributes?.body?.processed ? this.stripTagsPipe
            .transform(resource?.attributes.body?.processed) : null;

          const metaDescription: string = descriptionPlainText ? this.shortenPipe
            .transform(descriptionPlainText, 160, '...', false) : null;

          // const language: string = resource?.attributes.langcode;
          const language = 'fr';

          const path = '';

          const ngPath = '/home/' + resource.id;

          let originPath: string = resource.attributes?.path?.alias;
          if (originPath?.indexOf(this.appConfig.getConfig().account) === 1) {
            originPath = originPath.substring(this.appConfig.getConfig().account.length + 1);
          }

          const privacyPolicyPath: string = this.titleToPathPipe
            .transform(resource?.relationships?.field_privacy_policy_page?.reference?.attributes?.title, language);

          const enableConsentManagement: boolean = resource?.attributes?.field_enable_consent_management;

          const fragment: string = this.titleToPathPipe.transform(resource.attributes.title);

          const privacyPolicyContent: string = resource?.relationships?.field_privacy_policy_page?.reference?.attributes?.body?.processed;

          const unavailable: boolean = resource?.attributes?.field_unavailable;

          // TODO get info from storeFront once the field will be added
          const clientName = '';

          const shareableSocialNetworks: SocialNetwork[] = resource?.attributes?.field_sharing_social_networks;

          const mainDomain: string = resource?.attributes?.field_store_custom_domains?.[0];

          const delayedOrder: DelayedOrder = {
            description: resource?.attributes?.field_delayed_order_description?.processed,
            enabled: resource?.attributes?.field_delayed_order_enabled,
            title: resource?.attributes?.field_delayed_order_label,
            mandatory: resource?.attributes?.field_delayed_order_mandatory,
            maxPeriod: resource?.attributes?.field_delayed_order_max_period,
            minPeriod: resource?.attributes?.field_delayed_order_min_period,
          };

          const storeFront: StoreFront = {
            id: resource?.id,
            type: resource?.type,
            title: resource?.attributes.title,
            slogan: resource?.attributes.field_slogan,
            clientName,
            body: resource?.attributes.body,
            logo,
            phone: resource?.attributes?.field_store_phone,
            address: resource?.attributes?.field_store_address,
            instagram: resource?.attributes?.field_instagram_link,
            pinterest: resource?.attributes?.field_pinterest_link,
            facebook: resource?.attributes?.field_facebook_link,
            defaultProductImage,
            cartMode: resource.attributes.field_cart_mode ? resource.attributes.field_cart_mode : CartModes.drupal,
            enableDetailView: resource.attributes.field_enable_product_details === true,
            theme: this.resourceToThemePipe.transform(resource.relationships?.field_theme_palette?.reference),
            snipcartApiKey: !this.appConfig.getConfig().devMode ? resource.attributes.field_snipcart_public_api_key : resource.attributes.field_snipcart_dev_api_key,
            snipcartCustomCheckoutInfo: resource?.attributes.field_snipcart_checkout_info?.value,
            snipcartCustomCheckoutOptInEnabled: !!resource?.attributes?.field_snipcart_checkout_optin,
            snipcartCustomCheckoutOptInLabel: resource?.attributes?.field_snipcart_checkout_optinlbl,
            googleAnalyticsId: resource.attributes.field_google_analytics_id,
            gtmContainerId: resource.attributes.field_gtm_container_id,
            snipcartCustomBasketInfo: resource?.attributes.field_snipcart_cart_info?.value,
            displayCategoriesAsPreview: resource?.attributes.field_display_cat_as_preview,
            mainDomain,
            shareableSocialNetworks,
            unavailable,
            fragment,
            appIcons,
            welcomeImage,
            metaDescription,
            language,
            path,
            ngPath,
            originPath,
            privacyPolicyPath,
            enableConsentManagement,
            privacyPolicyContent,
            delayedOrder,
            homePage,
            icon: iconImage,
            mailchimpAccountId: resource?.attributes?.field_mailchimp_client_id,
            mailchimpAccountName: resource?.attributes?.field_mailchimp_client_name,
            mailchimpAccountServerPrefix: resource?.attributes?.field_mailchimp_server_prefix,
            showDescriptionPreview: resource?.attributes?.field_show_description_preview === true
          };

          return storeFront;

        })
      );

    }

    return of(null);

  }

}
