<form [formGroup]="form">
  <div class="layout-group">
    <ng-container *ngrxLet="title$; let title">
      <h1 class="snipcart__font--subtitle" [innerHTML]="title ? title : 'Je souhaite avoir ma commande'"></h1>
    </ng-container>
    <mat-radio-group formControlName="shippingDateMode">
      <mat-radio-button value="asap">Dés que possible</mat-radio-button>
      <mat-radio-button value="custom"  (click)="picker.open()">
        <ng-container *ngIf="form.value.date === ''">
          <span>Pour une date en particulier</span>
        </ng-container>
        <ng-container *ngIf="form.value.date !== ''">
          <span>Le {{(choosenDate$|async)|date:'EEEE d MMMM':'UTC'}}</span>
        </ng-container>
      </mat-radio-button>
    </mat-radio-group>

    <input
      class="date-input"
      matInput
      formControlName="date"
      [value]="minDate$|async"
      [min]="minDate$|async"
      [max]="maxDate$|async"
      [matDatepicker]="picker">
    <mat-datepicker
      #picker
      panelClass="datepicker-delayed-pickup">
    </mat-datepicker>

    <ng-container *ngIf="description$|async as description">
      <div [innerHTML]="description" class="description"></div>
    </ng-container>

  </div>
</form>
