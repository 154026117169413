import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {DataLoadingInterceptor} from "./data-loading-interceptor";


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: DataLoadingInterceptor, multi: true},
  ]
})
export class LoadingModule { }
