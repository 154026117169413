import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgModule,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepicker, MatDatepickerModule} from '@angular/material/datepicker';
import * as _moment from 'moment';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {filter, map, shareReplay, take, tap} from 'rxjs/operators';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {SnipcartService} from '../../snipcart/snipcart.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CartCustomField, Snipcart} from '../../snipcart/snipcart.model';
import {DELAYED_ORDER_CONFIGURATION} from '../../providers/storeFront.providers';
import {DelayedOrder} from '../../model/store-front';
import {ReactiveComponentModule} from '@ngrx/component';

@UntilDestroy()
@Component({
  selector: 'app-delivery-date',
  templateUrl: './delivery-date.component.html',
  styleUrls: ['./delivery-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom
})
export class DeliveryDateComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('picker') datePicker: MatDatepicker<any>;

  minDate$: Observable<Moment>;
  maxDate$: Observable<Moment>;

  form: FormGroup = this.fb.group(
    {
      shippingDateMode: [''],
      date: ['']
    }
  );

  choosenDate$: Observable<string>;

  fieldName = 'delayedOrder';

  private snipcart: Snipcart;

  title$: Observable<string> = this.delayedOrder$.pipe(
    filter(delayedOrder => !!delayedOrder),
    map(delayedOrder => delayedOrder.title)
  );

  description$: Observable<string> = this.delayedOrder$.pipe(
    filter(delayedOrder => !!delayedOrder),
    map(delayedOrder => delayedOrder.description)
  );

  delayedOrderValue: string;

  snipcartUpdating = false;

  instance: number;

  constructor(
    @Inject(DELAYED_ORDER_CONFIGURATION) private delayedOrder$: Observable<DelayedOrder>,
    private fb: FormBuilder,
    private snipcartService: SnipcartService,
    private cdr: ChangeDetectorRef
  ) {
    this.instance = Math.random() * 0xFFFFFF;
  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {

    this.snipcartService.snipcartOrderConfirmed$
      .pipe(
        untilDestroyed(this),
        filter(snipcartOrderConfirmed => !!snipcartOrderConfirmed)
      )
      .subscribe(snipcartOrderConfirmed => {
        delete this.delayedOrderValue;
        this.form.reset();
      });

    this.minDate$ = this.delayedOrder$.pipe(
      untilDestroyed(this),
      filter(delayedOrder => !!delayedOrder),
      map(delayedOrder => {
        return _moment().add(delayedOrder.minPeriod, 'days');
      })
    );

    this.maxDate$ = this.delayedOrder$.pipe(
      untilDestroyed(this),
      filter(delayedOrder => !!delayedOrder),
      map(delayedOrder => {
        return _moment().add(delayedOrder.maxPeriod, 'days');
      })
    );

  }

  ngAfterViewInit(): void {

    const valueChanges$: Observable<any> = this.form.valueChanges.pipe(
      shareReplay(1)
    );

    valueChanges$.pipe(
      untilDestroyed(this)
    ).subscribe(value => {

      if (value?.shippingDateMode === 'custom') {
        // this.datePicker.open();
      } else if (value?.shippingDateMode === 'asap') {
        if (value.date !== '') {
          this.form.get('date').setValue('', {onlySelf: false, emitEvent: true});
        }
        this.updateCustomField('asap');
      }

      this.cdr.detectChanges();

    });

    this.choosenDate$ = valueChanges$.pipe(
      filter(value => {
        return !!value?.date;
      }),
      map(value => value.date),
      tap((date: Moment) => {
        this.updateCustomField(date.format('YYYY/MM/DD'));
      }),
      map(date => date.toISOString()),
      tap((date) => {
        this.cdr.detectChanges();
      }),
    );

    this.datePicker.closedStream
      .pipe(untilDestroyed(this))
      .subscribe(closed => {
        if (this.form.value.date === '') {
          this.form.get('shippingDateMode').setValue('asap', {onlySelf: false, emitEvent: true});
        }
      });

    this.snipcartService.snipcart$.pipe(
      untilDestroyed(this),
      take(1)
    ).subscribe(snipcart => {
      this.snipcart = snipcart;
      const customField: CartCustomField = this.getDeliveryDateCartCustomField();
      if (customField) {
        if (customField?.value === 'asap') {
          this.form.get('shippingDateMode').setValue('asap');
        } else {
          this.form.get('shippingDateMode').setValue('custom');
          const date: Moment = _moment(customField?.value, 'YYYY/MM/DD');
          this.form.get('date').setValue(date);
          this.form.get('date').updateValueAndValidity();
        }
      }

    });

    this.snipcartService.snipcartStore$
      .pipe(
        untilDestroyed(this),
        filter(snipcartStore => !!snipcartStore)
      )
      .subscribe(() => {
        if (!!this.delayedOrderValue) {
          this.updateCustomField(this.delayedOrderValue);
        }
      });

  }

  updateCustomField(value: string) {

    const existingCustomField: CartCustomField = this.getDeliveryDateCartCustomField();
    if (this.snipcartUpdating === false && (!existingCustomField || (existingCustomField && existingCustomField.value !== value))) {

      this.delayedOrderValue = value;

      let newCustomField: CartCustomField;
      if (existingCustomField) {
        existingCustomField.value = value;
      } else {
        newCustomField = {
          displayValue: true,
          name: this.fieldName,
          required: true,
          type: 'textbox',
          value
        };
      }

      this.snipcartUpdating = true;

      this.snipcart.api.cart.update({
        customFields: [
          ...this.getSnipcartState()?.cart.customFields,
          ...(newCustomField ? [newCustomField] : [])
        ]
      }).then((result => {
        this.snipcartUpdating = false;
      }));

    }

  }

  getSnipcartState(): any {
    return this.snipcart?.store.getState();
  }

  getDeliveryDateCartCustomField(): CartCustomField {
    const existingCustomField: CartCustomField = this.getSnipcartState()?.cart.customFields
      .filter(cf => cf.name === this.fieldName).shift();
    return existingCustomField;
  }

}

@NgModule({
  declarations: [
    DeliveryDateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    MatRadioModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatInputModule,
    ReactiveComponentModule
  ],
  exports: [DeliveryDateComponent]
})

export class DeliveryDateModule {

  constructor() {
  }

}
