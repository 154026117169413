import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {combineLatest, fromEvent, Observable, ReplaySubject} from 'rxjs';
import {debounceTime, startWith, tap, withLatestFrom} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserActiveService {

  private mousemove$: Observable<any> = fromEvent(this.document, 'mousemove', {passive: true});
  private click$: Observable<any> = fromEvent(this.document, 'click', {passive: true}).pipe(startWith(''));
  private scroll$: Observable<any> = fromEvent(this.document, 'scroll', {passive: true}).pipe(startWith(''));

  userInteracting$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    this.userInteracting$.next(true);
    combineLatest([this.scroll$, this.mousemove$])
      .pipe(
        withLatestFrom(this.userInteracting$),
        tap(([event, userInteracting]) => {
          if (userInteracting === false) {
            this.userInteracting$.next(true);
          }
        }),
        debounceTime(1000)
      )
      .subscribe(mousemove => {
        this.userInteracting$.next(false);
      });

  }
}
