import {createAction, props} from '@ngrx/store';
import {BottomSheetOptions, DialogOptions} from './window.model';

export const openWindow = createAction(
  '[Window] Open Window',
  props<{ options?: DialogOptions, closePath?: string }>()
);

export const openBottomSheet = createAction(
  '[Window] Open Bottom Sheet',
  props<{ options: BottomSheetOptions, closePath?: string }>()
);

export const addWindowId = createAction(
  '[Window] Add Window Id',
  props<{ windowId: string }>()
);


export const removeWindowId = createAction(
  '[Window] Remove Window Id',
  props<{ windowId: string }>()
);
