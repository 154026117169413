import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromWindow from './window.reducer';
import {EffectsModule} from '@ngrx/effects';
import {WindowEffects} from './window.effects';
import {MatDialogModule} from '@angular/material/dialog';
import {WindowComponent} from './window/window.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {BottomSheetComponent} from './bottom-sheet/bottom-sheet.component';
import {OpenWindowComponent} from './open-window/open-window.component';
import {DrupalRouterModule} from "../drupal-router/drupal-router.module";
import {CoreUtilsModule} from "../core/core-utils.module";


@NgModule({
  declarations: [WindowComponent, BottomSheetComponent, OpenWindowComponent],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromWindow.windowFeatureKey, fromWindow.reducer),
        EffectsModule.forFeature([WindowEffects]),
        MatDialogModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatBottomSheetModule,
        DrupalRouterModule,
        CoreUtilsModule
    ]
})
export class WindowModule {
}
