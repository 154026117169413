import {Pipe, PipeTransform} from '@angular/core';
import {StoreResource} from 'ngrx-json-api';
import {ParagraphLocation} from '../model/paragraph-location';
import {ParagraphYoutube} from '../model/paragraph-youtube';
import {ResourceTypes} from '../ngrx-json-api/ngrx-json-api-definitions';
import {ResourceToParagraphLocationPipe} from './resource-to-paragraph-location.pipe';
import {ResourceToParagraphYoutubePipe} from './resource-to-paragraph-youtube.pipe';
import {ResourceToParagraphTextPipe} from './resource-to-paragraph-text.pipe';
import {TrurthifyPipe} from 'ngx-pipes';
import {ParagraphPrivacy} from '../model/paragraph-privacy';
import {ParagraphImages} from '../model/paragraph-images';
import {ResourceToParagraphImagesPipe} from './resource-to-paragraph-images.pipe';
import {ResourceToParagraphNewsletterPipe} from "./resource-to-paragraph-newsletter.pipe";
import {ParagraphNewsletter} from "../model/paragraph-newsletter";
import {StorePageParagraph} from "../model/store-page";
import {ResourceToParagraphProductsPipe} from "./resource-to-paragraph-products.pipe";

@Pipe({
  name: 'resourcesToParagraphs'
})
export class ResourcesToParagraphsPipe implements PipeTransform {

  constructor(
    private resourceToParagraphLocationPipe: ResourceToParagraphLocationPipe,
    private resourceToParagraphYoutubePipe: ResourceToParagraphYoutubePipe,
    private resourceToParagraphTextPipe: ResourceToParagraphTextPipe,
    private resourceToParagraphImagesPipe: ResourceToParagraphImagesPipe,
    private resourceToParagraphNewsletterPipe: ResourceToParagraphNewsletterPipe,
    private resourceToParagraphProductsPipe: ResourceToParagraphProductsPipe,
    private trurthifyPipe: TrurthifyPipe
  ) {

  }

  transform(resources: StoreResource[]): StorePageParagraph[] {
    if (resources) {

      const paragraphs: StorePageParagraph[] = resources.map(rawParagraph => {
        let paragraph: StorePageParagraph;
        if (rawParagraph.type === ResourceTypes.paragraphPageLocation) {
          paragraph = this.resourceToParagraphLocationPipe.transform(rawParagraph);
        } else if (rawParagraph.type === ResourceTypes.paragraphPageVideo) {
          paragraph = this.resourceToParagraphYoutubePipe.transform(rawParagraph);
        } else if (rawParagraph.type === ResourceTypes.paragraphPageText) {
          paragraph = this.resourceToParagraphTextPipe.transform(rawParagraph);
        } else if (rawParagraph.type === ResourceTypes.paragraphPagePrivacy) {
          paragraph = this.resourceToParagraphTextPipe.transform(rawParagraph);
        } else if (rawParagraph.type === ResourceTypes.paragraphPageImages) {
          paragraph = this.resourceToParagraphImagesPipe.transform(rawParagraph);
        } else if (rawParagraph.type === ResourceTypes.paragraphPageNewsletterSubscription) {
          paragraph = this.resourceToParagraphNewsletterPipe.transform(rawParagraph);
        } else if (rawParagraph.type === ResourceTypes.paragraphPageProducts) {
          paragraph = this.resourceToParagraphProductsPipe.transform(rawParagraph);
        }
        return paragraph;
      });
      return this.trurthifyPipe.transform(paragraphs);

    }

    return null;
  }

}
