import {Inject, Injectable} from '@angular/core';
import {DefaultUrlSerializer, UrlTree} from '@angular/router';
import {UrlsService} from './urls.service';
import {WINDOW} from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root'
})
export class CustomUrlSerializer extends DefaultUrlSerializer {

  constructor(
    private urlsService: UrlsService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
  }

  private _beautifyUrl(url: string, tree: UrlTree): string {
    /*if (url.startsWith('/products/331408')) {
      console.log('url', url);
      console.log('this.urlsService.angularRoutesToContentPaths', this.urlsService.angularRoutesToContentPaths);
    }*/
    const origin: string = this.window.location.origin;
    const urlObject: URL = new URL(origin + url);
    // console.log('urlObject.hash', urlObject.hash);
    // const snipcartHash: string = urlObject.hash.indexOf('/') > -1 ? urlObject.hash : '';

    let beautifyUrl: string = this.urlsService.angularRoutesToContentPaths[url] ?
      this.urlsService.angularRoutesToContentPaths[url] : url;
    // console.log('beautifyUrl url', url);
    if (beautifyUrl.split('').pop() !== '/') {
      beautifyUrl = beautifyUrl + '/';
    }
    /*const beautifyUrlObject: URL = new URL(origin + url);
    console.log('beautifyUrlObject', beautifyUrlObject);
    if (beautifyUrlObject.pathname.split('').pop() !== '/') {
      beautifyUrl = beautifyUrlObject.pathname + '/';
    }
    beautifyUrl = beautifyUrl + beautifyUrlObject.hash;
    console.log('beautifyUrl', beautifyUrl);*/
    return beautifyUrl;
  }

  private _reverseUrl(url: string): string {
    const processedUrl: string = url;
    const reverseUrl: string = this.urlsService.contentPathsToAngularRoutes[processedUrl] ?
      this.urlsService.contentPathsToAngularRoutes[processedUrl] : processedUrl;
    return reverseUrl;
  }

  parse(url: string): UrlTree {
    return super.parse(this._reverseUrl(url));
  }

  serialize(tree: UrlTree): string {
    return this._beautifyUrl(super.serialize(tree), tree);
  }

}
