import {Pipe, PipeTransform} from '@angular/core';
import {ParagraphYoutube} from "../model/paragraph-youtube";
import {Resource} from "ngrx-json-api";
import {AppConfigService} from "../config/app-config.service";

@Pipe({
  name: 'resourceToParagraphYoutube'
})
export class ResourceToParagraphYoutubePipe implements PipeTransform {

  constructor(private appConfig: AppConfigService) {
  }

  transform(resource: Resource): ParagraphYoutube {

    if (resource) {
      const {id, type} = resource;
      const video: any = resource?.relationships?.field_video?.reference;
      const title: string = video.attributes?.name;
      const url: string = video.attributes?.field_media_oembed_video;
      const thumbnailUrl: string = this.appConfig.getConfig().backend + resource?.relationships?.field_video?.reference?.relationships?.thumbnail?.reference?.attributes?.uri?.url;
      const paragraphYoutube: ParagraphYoutube = {id, type, title, url, thumbnailUrl};
      return paragraphYoutube;
    }

    return null;
  }

}
