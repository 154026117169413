import { Angulartics2Settings, RouterlessTracking, TrackNavigationEnd } from 'angulartics2';
import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';

@Injectable()
export class AnalyticsTracking implements RouterlessTracking {

  constructor(protected location: Location, @Inject(WINDOW) protected window:Window) {
  }

  prepareExternalUrl(url: string): string {
    return url;
  }

  trackLocation(settings: Angulartics2Settings): Observable<TrackNavigationEnd> {
    return new Observable<TrackNavigationEnd>((subscriber)=>{
      subscriber.next({url: this.location.path()});
      this.location.onUrlChange((url, state)=>{
        let path: string;
        const urlObj = new URL(url, this.window.location.origin);
        subscriber.next({url: urlObj.pathname});
      });
    });
  }
}

