import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AppConfigService} from './config/app-config.service';
import {catchError, switchMap} from 'rxjs/operators';
import {throwError} from 'rxjs';

// https://itnext.io/angular-universal-caching-transferstate-96eaaa386198

@Injectable()
export class StoreUuidInterceptor implements HttpInterceptor {

  constructor(
    private appConfigService: AppConfigService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    const isJsonApi: boolean = req.url.indexOf('/jsonapi/') > -1;
    const isGet: boolean = req.method === 'GET';
    const isNode: boolean = req.url.indexOf('/node/') > -1;
    const isProduct: boolean = req.url.indexOf('/commerce_product/') > -1;
    const isVariant: boolean = req.url.indexOf('/commerce_product_variation/') > -1;

    const isGlobalFilterable: boolean = isGet && isJsonApi && (isNode || isProduct || isVariant);
    let isCollection: boolean;
    if (isGlobalFilterable) {
      const url: URL = new URL(req.url);
      const pathNameSplit: string[] = url.pathname.split('/');
      isCollection = pathNameSplit.length === 4;
    }
    if (isGlobalFilterable && isCollection) {
      return this.appConfigService.appConfig$.pipe(
        switchMap(config => {
          req = req.clone(
            {
              url: req.url + '&global_filter[store_uuid]=' + config.account
            }
          );
          return next.handle(req);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
    }

    return next.handle(req);

  }

}
