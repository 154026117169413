import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';
import {Price, Variant} from '../model/product';
import {Image} from '../model/image';

@Pipe({
  name: 'resourceToVariant'
})
export class ResourceToVariantPipe implements PipeTransform {

  transform(resource: Resource, productTitle: string, image: Image, plainTextDescription: string): Variant {

    if (resource) {

      const {id, type} = resource;
      const title: string = productTitle;
      const unit: string = resource.attributes?.field_product_unit;
      const available: boolean = resource.attributes?.field_product_available;
      const price: Price = {...resource.attributes?.price, number: parseFloat(resource.attributes?.price.number)};
      const taxable: boolean = resource.attributes?.field_product_taxable;
      const taxIncluded: boolean = resource.attributes?.field_tax_inclusive_price;
      const sku: string = resource.attributes?.sku;
      const listPrice: Price = resource.attributes?.list_price;

      const variant: Variant = {
        id,
        type,
        available,
        title,
        price,
        listPrice,
        taxable,
        taxIncluded,
        unit,
        sku,
        image,
        plainTextDescription
      };

      return variant;

    }

    return null;

  }

}
