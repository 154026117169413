import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {iif, Observable, of} from 'rxjs';
import {catchError, delay, filter, mergeMap, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {UrlsService} from './urls.service';
import {Store} from '@ngrx/store';
import {PathService} from './drupal-router/path.service';
import {DrupalPathInfos, PathInfosEntity} from './drupal-router/path-infos.model';
import {ResourceTypes} from './ngrx-json-api/ngrx-json-api-definitions';
import {DrupalNode} from './model/drupal-node';
import {AppConfigService} from './config/app-config.service';
import {STORE_FRONT} from './providers/storeFront.providers';
import {StoreFront} from './model/store-front';
import {ResourceIdentifier} from '@madeinlune/ngrx-json-api/src/interfaces';
import {addProductToMap} from './store/luneshop/actions/lune-shop.actions';
import {ROUTE_SINGLE_CATEGORY} from './providers/route.providers';
import {WINDOW} from '@ng-web-apis/common';
import {TransferStateService} from '@scullyio/ng-lib';

@Injectable()
export class UrlGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
    private pathService: PathService,
    private urlsService: UrlsService,
    private appConfigService: AppConfigService,
    @Inject(STORE_FRONT) private storeFront$: Observable<StoreFront>,
    @Inject(ROUTE_SINGLE_CATEGORY) private routeSingleCategory$: Observable<boolean>,
    @Inject(WINDOW) private window: Window,
    private transferStateService: TransferStateService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    return this.storeFront$.pipe(
      filter(storeFront => !!storeFront),
      take(1),
      switchMap(storeFront => {

        const origin: string = this.window.location.origin;
        const urlObject: URL = new URL(origin + state.url);
        const routerUrl: string = urlObject.pathname;

        /*if (urlObject.hash.indexOf('#/') > -1) {
          return of(true);
        }*/

        const accountId: string = this.appConfigService.getConfig().account;
        let drupalPath: string;
        if (routerUrl === '/' || routerUrl === '/fr') {
          drupalPath = accountId + storeFront.originPath;
        } else {
          drupalPath = '/' + accountId + routerUrl.slice(3);
        }

        if (drupalPath.indexOf('/product/') > -1 || drupalPath.indexOf('/node/') > -1) {
          const splitPath: string[] = drupalPath.split('/');
          splitPath.shift();
          splitPath.shift();
          drupalPath = splitPath.join('/');
        }

        const retrievePathInfos$: Observable<DrupalPathInfos> = this.transferStateService.useScullyTransferState(
          drupalPath,
          this.pathService.resolve(drupalPath)
        ).pipe(
          mergeMap((pathInfos) => {
            return iif(() => {
                return !!pathInfos;
              },
              of(pathInfos),
              this.pathService.resolve(drupalPath)
            );
          })
        );

        const pathInfos$: Observable<boolean | UrlTree> =
          retrievePathInfos$.pipe(
            filter(pathInfos => !!pathInfos),
            take(1),
            withLatestFrom(this.routeSingleCategory$),
            switchMap(([pathInfos, routeSingleCategory]) => {
              const entity: PathInfosEntity | undefined = pathInfos.entity;
              return this.getParsedUrl$(
                entity.type + '--' + entity.bundle,
                entity.uuid,
                pathInfos?.metatags?.title?.attributes?.content,
                pathInfos?.language,
                routerUrl,
                urlObject,
                storeFront,
                routeSingleCategory
              );
            }),
            delay(0),
            catchError(error => {
              return this.getParsedUrl$(
                storeFront.type,
                storeFront.id,
                storeFront.title,
                storeFront.language,
                '/fr/',
                urlObject,
                storeFront,
                false
              );
            })
          );
        const slicedRouterUrl: string = routerUrl.slice(-1) === '/' ? routerUrl.slice(0, -1) : routerUrl;
        return iif(() => {
            const content: ResourceIdentifier = this.urlsService.contentPathsToResourceIdentifier[slicedRouterUrl];
            /*console.log('content this.urlsService.contentPathsToResourceIdentifier', this.urlsService.contentPathsToResourceIdentifier);
            console.log('content slicedRouterUrl', slicedRouterUrl);
            console.log('content routerUrl', routerUrl);
            console.log('content', content);*/
            return !!content;
          },
          of(this.urlsService.contentPathsToResourceIdentifier[slicedRouterUrl]).pipe(
            withLatestFrom(this.routeSingleCategory$),
            switchMap(([content, routeSingleCategory]) => {
              return this.getParsedUrl$(
                content.type,
                content.id,
                content.title,
                content.language,
                routerUrl,
                urlObject,
                storeFront,
                routeSingleCategory
              );
            })
          ),
          pathInfos$
        );
      })
    );
  }

  getParsedUrl$(
    type: string,
    id: string,
    title: string,
    language: string,
    routerUrl: string,
    urlObject: URL,
    storeFront: DrupalNode,
    routeSingleCategory: boolean
  ): Observable<UrlTree> {

    let routerUrlWithoutLang: string;

    // console.log('routerUrl', routerUrl);

    const isHome: boolean = routerUrl === '/' || routerUrl === '/fr' || routerUrl === '/fr/';
    if (isHome) {
      routerUrlWithoutLang = '';
    } else if (routerUrl.indexOf('/fr/') > -1) {
      routerUrlWithoutLang = routerUrl.slice(3);
    }

    if (type && id) {
      // TODO remove hard coded language
      const nanoDrupalNode: DrupalNode = {
        type,
        id,
        title,
        path: routerUrlWithoutLang,
        language: 'fr'
      };
      this.urlsService.addUrlInfos([nanoDrupalNode], routeSingleCategory);

      let angularUrl: string;
      let parsedUrl: UrlTree;

      if (type === ResourceTypes.productDefault) {
        this.store.dispatch(addProductToMap({product: nanoDrupalNode}));
        angularUrl = '/products/' + id;
      } else if (type === ResourceTypes.storeFront) {
        angularUrl = '/home/' + id + '#appWelcome';
      } else if (type === ResourceTypes.storePage) {
        angularUrl = '/pages/' + id;
      } else if (type === ResourceTypes.storeSection) {
        if (!routeSingleCategory) {
          angularUrl = '/home/' + storeFront.id + '#section--' + id;
        } else {
          angularUrl = '/categories/' + id;
        }
      } else {
        angularUrl = '';
      }
      const hash: string = urlObject.hash;
      const processedHash: string = (hash.indexOf('#/') > -1 ? '&snipcartStep=' + hash.substring(2) : '');
      // console.log('angularUrl', angularUrl);
      // console.log('hash', hash);
      parsedUrl = this.router.parseUrl(angularUrl + hash);
      return of(parsedUrl);

    }

    return of(this.router.parseUrl(''));
  }

}
