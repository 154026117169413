import {Action, createReducer, on} from '@ngrx/store';
import * as AnalyticsActions from './analytics.actions';

export const analyticsFeatureKey = 'analytics';

export interface State {
  accountId: string;
}

export const initialState: State = {
  accountId: null
};


const analyticsReducer = createReducer(
  initialState,

  on(AnalyticsActions.initializeAnalytics, (state, {accountId}) => {
    return ({
      ...state,
      accountId
    });
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return analyticsReducer(state, action);
}

