<ng-container *ngIf="(passport$|async) as passport">

  <ng-container *ngIf="passport.title">

    <mat-toolbar
      class="title-bar-group flex-shrink-0 d-flex justify-content-between align-items-center flex-none px-3"
      [color]="passport.barColor">

      <ng-template
        [ngTemplateOutlet]="simpleTitleTemplate"
        [ngTemplateOutletContext]="{title: passport.title}">
      </ng-template>

      <ng-container
        *ngIf="passport.showCloseButton">

        <button [matDialogClose] mat-icon-button class="d-flex p-0 close-button" [color]="passport.closeButtonColor">

          <mat-icon
            [svgIcon]="passport?.closeIcon"
            class="d-flex close-icon"
            aria-label="Fermer la fenêtre">
          </mat-icon>

        </button>

      </ng-container>


    </mat-toolbar>

  </ng-container>


  <mat-dialog-content class="d-flex flex-fill flex-column overflow-y-auto position-relative"
                      [ngClass]="passport.contentClasses">

    <ng-container
      *ngIf="passport.components?.content as contentDef"
      [libComponentGenerator]="contentDef?.component" [inputs]="contentDef?.inputs">
    </ng-container>

    <ng-container *ngIf="windowData?.contentTemplate as contentTemplate">
      <ng-template
        [ngTemplateOutlet]="contentTemplate">
      </ng-template>
    </ng-container>

    <ng-container
      *ngIf="passport.message as message">

      <div [innerHTML]="message" class="window-message"></div>

    </ng-container>

  </mat-dialog-content>

  <ng-container *ngIf="actions$|async as actions">

    <mat-dialog-actions class="d-flex flex-shrink-0 justify-content-center">

      <ng-template ngFor [ngForOf]="actions" let-action>

        <button
          (click)="onActionClick(action)"
          mat-flat-button
          [mat-dialog-close]="action.autoClose"
          [color]="action.color"
          [ngClass]="action.classes">
          <span>{{action?.label}}</span>
        </button>

      </ng-template>

    </mat-dialog-actions>

  </ng-container>

</ng-container>


<ng-template #simpleTitleTemplate let-title="title">
  <h2 class="title">{{title}}</h2>
</ng-template>
