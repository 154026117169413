import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GetProductImagePipe} from './get-product-image.pipe';
import {AddressPipe} from './address.pipe';
import {SocialLinksInfosPipe} from './social-links-infos.pipe';
import {YouTubeVideoIdPipe} from './you-tube-video-id-pipe';
import {ResourceToCategoryPipe} from './resource-to-category.pipe';
import {ResourceToProductPipe} from './resource-to-product.pipe';
import {ResourceToVariantPipe} from './resource-to-variant.pipe';
import {TitleToPathPipe} from './title-to-path.pipe';
import {ResourceToImagePipe} from './resource-to-image.pipe';
import {GetProfilePipe} from './get-profile.pipe';
import {ResourceToStoreFrontPipe} from './resource-to-store-front.pipe';
import {ResourceToStorePagePipe} from './resource-to-store-page.pipe';
import {ResourceToParagraphLocationPipe} from './resource-to-paragraph-location.pipe';
import {ResourceToParagraphYoutubePipe} from './resource-to-paragraph-youtube.pipe';
import {ResourcesToParagraphsPipe} from './resources-to-paragraphs.pipe';
import {ResourceToThemePipe} from './resource-to-theme.pipe';
import {ResourceToParagraphTextPipe} from './resource-to-paragraph-text.pipe';
import {ResourceToParagraphImagesPipe} from './resource-to-paragraph-images.pipe';
import {ResourceToPathInfosPipe} from './resource-to-path-infos.pipe';
import {LatinisePipe, OrderByPipe, ShortenPipe, SlugifyPipe, StripTagsPipe, TrurthifyPipe} from 'ngx-pipes';
import {CategoriesMenuBuilderPipe} from './categories-menu-builder.pipe';
import {ResourceToParagraphNewsletterPipe} from './resource-to-paragraph-newsletter.pipe';
import {ResourceToAlertPipe} from './resource-to-alert.pipe';
import {ProductMinMaxPricePipe} from './product-min-max-price.pipe';
import {ResourceToParagraphProductsPipe} from './resource-to-paragraph-products.pipe';


const pipes = [
  GetProductImagePipe,
  YouTubeVideoIdPipe,
  AddressPipe,
  SocialLinksInfosPipe,
  ResourceToCategoryPipe,
  ResourceToProductPipe,
  ResourceToVariantPipe,
  TitleToPathPipe,
  ResourceToImagePipe,
  GetProfilePipe,
  ResourceToStoreFrontPipe,
  ResourceToStorePagePipe,
  ResourceToParagraphLocationPipe,
  ResourceToParagraphYoutubePipe,
  ResourcesToParagraphsPipe,
  ResourceToParagraphProductsPipe,
  ResourceToPathInfosPipe,
  ResourceToThemePipe,
  ResourceToParagraphTextPipe,
  ResourceToParagraphImagesPipe,
  CategoriesMenuBuilderPipe,
  ResourceToParagraphNewsletterPipe,
  ResourceToAlertPipe,
  ProductMinMaxPricePipe
];

@NgModule({
  declarations: [
    ...pipes,
  ],
  exports: [...pipes],
  imports: [
    CommonModule
  ],
  providers: [
    ...pipes,
    LatinisePipe,
    SlugifyPipe,
    StripTagsPipe,
    ShortenPipe,
    TrurthifyPipe,
    OrderByPipe
  ]
})
export class PipesModule {
}
