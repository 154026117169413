import {createAction, props} from '@ngrx/store';
import {SnipcartSession} from './snipcart.model';
import {CartState} from './snipcart.reducer';

export const openCart = createAction(
  '[Snipcart] Open Cart'
);

export const closeCart = createAction(
  '[Snipcart] Close Cart'
);

export const setCartState = createAction(
  '[Snipcart] Set Cart State',
  props<{ cartState: CartState }>()
);


export const setCartSession = createAction(
  '[Snipcart] Set Cart Session',
  props<{ cartSession: SnipcartSession }>()
);

export const setPublicApiKey = createAction(
  '[Snipcart] Set Public Api Key',
  props<{ apiKey: string }>()
);

export const setSettings = createAction(
  '[Snipcart] Set Settings',
  props<{ settings: any }>()
);
