// token to access a stream with the information you need
import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {Address} from '../../model/address';
import {CategoryMenu, CategoryMenuItem} from '../../model/category-menu';

export const CATEGORIES_MENU = new InjectionToken<Observable<CategoryMenu>>(
  'CATEGORIES_MENU'
);

export const CURRENT_CATEGORY_MENU_ITEM = new InjectionToken<Observable<CategoryMenuItem>>(
  'CURRENT_CATEGORY_MENU_ITEM'
);

export const PHONE = new InjectionToken<Observable<string>>(
  'A stream with the shop phone'
);

export const ADDRESS = new InjectionToken<Observable<Address>>(
  'A stream with the shop address'
);

export const CATEGORIES_ROUTE_SINGLE_CATEGORY = new InjectionToken<boolean>(
  'CATEGORIES_ROUTE_SINGLE_CATEGORY'
);
