import {createReducer, on} from '@ngrx/store';
import * as AlertsActions from './alerts.actions';
import {Alert} from "./alert.models";

export const alertsFeatureKey = 'alerts';

export interface State {

  alerts: Alert[];
  visible: boolean;

}

export const initialState: State = {

  alerts: [],
  visible: true

};


export const reducer = createReducer(
  initialState,

  on(AlertsActions.setAlerts, (state, action) => {
    return {
      ...state,
      alerts: action.alerts
    };
  }),
  on(AlertsActions.removeAlert, (state, action) => {

    const alertIndex: number = state.alerts.findIndex(alert => alert.id === action.alert.id);

    return {
      ...state,
      alerts: [
        ...state.alerts.slice(0, alertIndex),
        ...state.alerts.slice(alertIndex + 1)
      ]
    };
  }),
  on(AlertsActions.hideAlerts, (state) => {
    return {
      ...state,
      visible: false
    };
  }),
  on(AlertsActions.showAlerts, (state) => {
    return {
      ...state,
      visible: true
    };
  }),
);

