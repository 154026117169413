import {Compiler, ComponentFactoryResolver, Injectable, Injector, TemplateRef, Type} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, map, shareReplay, take, tap} from 'rxjs/operators';

export enum ComponentsIds {
  components = 'components',
  loading = 'loading',
  informations = 'informations',
  cart = 'cart',
  checkout = 'checkout',
  pageContact = 'pageContact',
  productOverview = 'productOverview',
  cookieBanner = 'cookieBanner'
}

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  templates: { [templateId: string]: TemplateRef<any> } = {};

  templates$: ReplaySubject<{ [templateId: string]: TemplateRef<any> }> = new ReplaySubject<{ [templateId: string]: TemplateRef<any> }>();

  components: { [componentId: string]: Type<any> } = {};

  components$: ReplaySubject<{ [componentId: string]: Type<any> }> = new ReplaySubject<{ [componentId: string]: Type<any> }>(1);

  public test: any;

  public loadingComponents: string[] = [];


  private componentFunctions: { [componentId: string]: any } = {
    [ComponentsIds.components]: this.createComponents,
    [ComponentsIds.loading]: this.createLoading,
    [ComponentsIds.informations]: this.createInformations,
    [ComponentsIds.pageContact]: this.createPageContact,
    [ComponentsIds.productOverview]: this.createProductOverview,
    [ComponentsIds.cookieBanner]: this.createCookieBanner
  };

  constructor(
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    private compiler: Compiler
  ) {
    this.components$.next(this.components);
  }

  addTemplate(templateId: string, template: TemplateRef<any>) {
    this.templates[templateId] = template;
    this.templates$.next(this.templates);
  }

  addComponent(componentId: string, component: Type<any>) {
    this.components[componentId] = component;
    this.components$.next(this.components);
  }

  getTemplate$(templateId: string): Observable<TemplateRef<any>> {
    return this.templates$.pipe(
      filter(templates => !!templates[templateId]),
      map(templates => {
        return templates[templateId];
      }));
  }

  getComponent$(componentId: string): Observable<Type<any>> {
    return this.components$.pipe(
      tap(components => {
        if (!components[componentId] && this.loadingComponents.indexOf(componentId) === -1) {
          this.loadingComponents.push(componentId);
          this.componentFunctions[componentId].apply(this);
        }
      }),
      map(components => {
        return components[componentId];
      }),
      filter(component => !!component),
      take(1),
      shareReplay(1)
    );
  }


  createComponents() {
    import('../made-in-lune/components/components.component').then(({ComponentsComponent}) => {
      this.addComponent(ComponentsIds.components, ComponentsComponent);
    });
  }

  createLoading() {
    import('../components/loading/loading.component').then(({LoadingComponent}) => {
      this.addComponent(ComponentsIds.loading, LoadingComponent);
    });
  }

  createInformations() {
    import('../components/informations/informations.component').then(({InformationsComponent}) => {
      this.addComponent(ComponentsIds.informations, InformationsComponent);
    });
  }

  createPageContact() {

    import('../components/informations/contact-page/contact-page.component').then(({ContactPageComponent}) => {
      this.addComponent(ComponentsIds.pageContact, ContactPageComponent);
    });

  }

  createProductOverview() {

    import('../components/product-overview/product-overview.component').then(({ProductOverviewComponent}) => {
      this.addComponent(ComponentsIds.productOverview, ProductOverviewComponent);
    });

  }

  createCookieBanner() {

    import('../components/cookie-banner/cookie-banner.component').then(({CookieBannerComponent}) => {
      this.addComponent(ComponentsIds.cookieBanner, CookieBannerComponent);
    });

  }

}
