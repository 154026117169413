import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';

export interface Breadcrumbs {
  breadcrumbs: Breadcrumb[];
}

export interface Breadcrumb {
  title: string;
  path: string;
  fragment?: string;
}

export const BREADCRUMBS = new InjectionToken<Observable<Breadcrumbs>>(
  'BREADCRUMBS'
);
