import {createAction, props} from '@ngrx/store';
import {Alert} from './alert.models';

export const setAlerts = createAction(
  '[Alerts] Set Alerts',
  props<{ alerts: Alert[] }>()
);

export const removeAlert = createAction(
  '[Alerts] Remove Alerts',
  props<{ alert: Alert }>()
);

export const showAlerts = createAction(
  '[Alerts] Show Alerts'
);

export const hideAlerts = createAction(
  '[Alerts] Hide Alerts'
);
