import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {LazyLoaderService} from './core/services/lazy-loader.service';
import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {WINDOW} from '@ng-web-apis/common';
import {ENABLE_GRADIENT_SCROLL} from './providers/ui.providers';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {AlertsComponent} from './components/alerts/alerts.component';
import {createCustomElement} from '@angular/elements';
import {UserActiveService} from './services/user-active.service';
import {CartMessageComponent} from './components/cart-message/cart-message.component';
import {Store} from '@ngrx/store';
import {CURRENT_PRODUCT_DEFAULT_CATEGORY} from './providers/catalog-products.providers';
import {Category} from './model/product-category';
import {Product} from './model/product';
import {StorePage} from './model/store-page';
import {StoreFront} from './model/store-front';
import {IdleMonitorService, isScullyGenerated, isScullyRunning, TransferStateService} from '@scullyio/ng-lib';
import {PathInfos} from './model/path-infos';
import {ResourceIdentifier} from '@madeinlune/ngrx-json-api';
import {DeliveryDateComponent} from './components/delivery-date/delivery-date.component';


export interface ScullyState {
  products: Product[];
  pages: StorePage[];
  categories: Category[];
  storeFront: StoreFront;
  pathInfos: { [url: string]: PathInfos };
  categoriesLoadingQueue: ResourceIdentifier[];
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private lazyLoaderService: LazyLoaderService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(ENABLE_GRADIENT_SCROLL) private enableGradientScroll$: Observable<boolean>,
    @Inject(PLATFORM_ID) protected platformId,
    @Inject(CURRENT_PRODUCT_DEFAULT_CATEGORY) protected currentProductDefaultCategory$: Observable<Category>,
    private renderer: Renderer2,
    private el: ElementRef,
    private injector: Injector,
    private userActiveService: UserActiveService,
    private appRef: ApplicationRef,
    private transferStateService: TransferStateService,
    private store: Store<any>,
    private ims: IdleMonitorService
  ) {
  }


  ngOnInit(): void {

    if (isScullyRunning()) {
      this.store.subscribe(store => {

        let products: Product[];
        let pages: StorePage[];
        let categories: Category[];
        let storeFront: StoreFront;
        let pathInfos: { [url: string]: PathInfos };
        let categoriesLoadingQueue: ResourceIdentifier[];

        if (store.catalogProducts) {
          products = Object.keys(store.catalogProducts.entities).map(key => store.catalogProducts.entities[key]);
        }

        if (store.catalogCategories) {
          categories = Object.keys(store.catalogCategories.entities).map(key => store.catalogCategories.entities[key]);
        }

        if (store.pages) {
          pages = Object.keys(store.pages.entities).map(key => store.pages.entities[key]);
        }

        if (store.luneShop?.categoriesLoadingQueue) {
          categoriesLoadingQueue = store.luneShop?.categoriesLoadingQueue;
        }

        if (store.luneShop?.storeFront) {
          storeFront = store.luneShop.storeFront;
        }

        if (store.luneShop?.pathInfosMap) {
          pathInfos = store.luneShop?.pathInfosMap;
        }

        const scullyState: ScullyState = {
          products,
          pages,
          categories,
          storeFront,
          pathInfos,
          categoriesLoadingQueue
        };

        console.log('scullyState', scullyState);

        this.transferStateService.setState('luneshop', scullyState);

      });
    } else if (isScullyGenerated()) {

    }

    if (isPlatformBrowser(this.platformId) && this.window) {
      let vh: number = this.window.innerHeight * 0.01;
      this.document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.window.addEventListener('resize', () => {
        // We execute the same script as before
        vh = this.window.innerHeight * 0.01;
        this.document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }

    setTimeout(() => {
      /*this.lazyLoaderService.loadModule(() =>
        import('./moon-shop/moon-shop.component').then(m => m.MoonShopModule)
      );*/
      this.lazyLoaderService.loadModule(() =>
        import('./components/alerts/alerts.component').then(m => m.AlertsModule)
      );
    });

    if (isPlatformBrowser(this.platformId)) {
      // tslint:disable-next-line:no-shadowed-variable
      const {createCustomElement} = require('@angular/elements');
      const AlertsElement = createCustomElement(AlertsComponent, {injector: this.injector});
      // Register the custom element with the browser.
      customElements.define('alerts-element', AlertsElement);

      const CartMessageElement = createCustomElement(CartMessageComponent, {injector: this.injector});
      // Register the custom element with the browser.
      customElements.define('cart-message-element', CartMessageElement);

      const DeliveryDateElement = createCustomElement(DeliveryDateComponent, {injector: this.injector});
      // Register the custom element with the browser.
      customElements.define('delivery-date-element', DeliveryDateElement);

    }

    /*require('../assets/icomoon/sprite.svg').then(result => {
      console.log(result);
    });*/

    const domain = (isPlatformServer(this.platformId)) ? this.document.location.origin + '/' : '';
    const icomoonSvg = domain + require('file-loader!../assets/icomoon/sprite.svg').default;
    this.matIconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl(icomoonSvg));

    this.enableGradientScroll$.pipe(take(1)).subscribe(enableGradientScroll => {
      if (enableGradientScroll) {
        this.renderer.addClass(this.el.nativeElement, 'hide-scrollbars');
      }
    });

  }

}
