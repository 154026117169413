import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {PATH_SERVICE_URL} from './drupal-router.providers';
import {DrupalPathInfos} from './path-infos.model';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';
import {isPlatformServer} from '@angular/common';

@Injectable(
  {providedIn: 'root'}
)
export class PathService {

  private pathStateKey: StateKey<DrupalPathInfos> = makeStateKey<DrupalPathInfos>('path');

  constructor(
    private httpClient: HttpClient,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) protected platformId,
    @Inject(PATH_SERVICE_URL) private readonly pathServiceUrl$: Observable<string>
  ) {
  }

  resolve(path): Observable<DrupalPathInfos> {
    const drupalPath = path === '' ? '/' : path;
    return this.pathServiceUrl$.pipe(
      filter(pathServiceUrl => !!pathServiceUrl),
      switchMap(pathServiceUrl => {

        if (this.transferState.hasKey(this.pathStateKey)) {
          const pathInfos: DrupalPathInfos = this.transferState.get(this.pathStateKey, null);
          this.transferState.remove(this.pathStateKey);
          return of(pathInfos);
        }

        return this.httpClient.get<DrupalPathInfos>(pathServiceUrl, {
          params: new HttpParams()
            .set('path', drupalPath),
          headers: new HttpHeaders({Accept: 'application/json'})
        }).pipe(
          map(result => {
            if (isPlatformServer(this.platformId)) {
              //this.transferState.set(this.pathStateKey, result);
            }
            return result as DrupalPathInfos;
          }),
          catchError((error: HttpErrorResponse) => {
            console.log('error', error);
            return throwError(error);
          })
        );
      }));


  }

}
