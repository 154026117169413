import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {TemplatesService} from './services/templates.service';
import {take} from 'rxjs/operators';

@Directive({
  selector: '[libComponentGenerator]'
})
export class ComponentGeneratorDirective implements OnInit, OnDestroy, OnChanges {

  @Input()
  set libComponentGenerator(value: string) {

    this.generateComponent(value);

  }

  @Input()
  inputs: { [propertyName: string]: any };

  componentRef: ComponentRef<any>;


  constructor(
    private vc: ViewContainerRef,
    private templatesService: TemplatesService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnDestroy() {
    this.vc.clear();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'inputs': {
            this.updateComponentInputs();
            break;
          }
          case 'componentRef': {
            this.updateComponentInputs();
            break;
          }
        }
      }
    }

  }

  updateComponentInputs() {
    if (this.componentRef && this.inputs) {
      const inputs: { [propertyName: string]: any } = this.inputs;
      if (inputs) {
        Object.entries(inputs).forEach((propertyKeyValue) => {
          const property: string = propertyKeyValue[0];
          const value: any = propertyKeyValue[1];
          this.componentRef.instance[property] = value;
        });
        this.componentRef.changeDetectorRef.markForCheck();
      }
    }
  }

  generateComponent(componentId: string): void {
    this.templatesService.getComponent$(componentId).pipe(take(1)).subscribe((contentComponent) => {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(contentComponent);
      this.vc.clear();
      if (this.componentRef) {
        this.componentRef.destroy();
      }
      this.componentRef = this.vc.createComponent<any>(componentFactory);
      this.updateComponentInputs();
      this.componentRef.changeDetectorRef.markForCheck();
    });
  }

}
