import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Integrations } from '@sentry/tracing';
import {init as initSentry } from '@sentry/angular';


if (environment.production) {
  enableProdMode();
  initSentry({
    dsn: 'https://428351c260544ae3bedb2e2f833b66fc@o231483.ingest.sentry.io/5457783',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [],
      }),
    ],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.'
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
  });
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
