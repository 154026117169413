import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreComponentsConfig} from './core-components-config';
import {TemplatesService} from './services/templates.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [],
  providers: [
    TemplatesService
  ]
})
export class MilCoreModule {

  constructor(@Optional() @SkipSelf() parentModule?: MilCoreModule) {
    if (parentModule) {
      throw new Error(
        'MilCoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: CoreComponentsConfig): ModuleWithProviders<MilCoreModule> {
    return {
      ngModule: MilCoreModule,
      providers: [
        {provide: CoreComponentsConfig, useValue: config}
      ]
    };
  }

}
