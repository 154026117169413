import {Direction, Query} from 'ngrx-json-api';
import {ResourceTypes} from './ngrx-json-api-definitions';

export enum ProductQueryIds {
  productCount = 'productCount',
  productFull = 'productFull',
  singleProduct = 'singleProduct',
  singleCategoryProductsFull = 'singleCategoryProductsFull'
}

export type ProductQueryId = ProductQueryIds.productFull;

export class NgrxJsonApiQueries {

  static readonly contains: string = 'CONTAINS';
  static readonly startsWith: string = 'STARTS_WITH';
  static readonly equals: string = '=';
  static readonly in: string = 'IN';

  static readonly queryIdStoreFront: string = 'storeFront';

  static readonly queryIdCategories: string = 'categories';

  static readonly queryIdPages: string = 'pages';

  static productsQueryBase: Query = {
    type: ResourceTypes.productDefault,
    params: {
      include: [
        'field_image',
        'field_media',
        'field_sections',
        'variations',
        'field_paragraphs.field_video.thumbnail',
        'field_paragraphs.field_images'
      ],
      filtering: [
        {
          path: 'status',
          value: 1
        }
      ],
      sorting: [
        {api: 'title', direction: Direction.ASC}
      ]
    },
  };

  static storeFrontNodeQuery: Query = {
    queryId: NgrxJsonApiQueries.queryIdStoreFront,
    type: ResourceTypes.storeFront,
    params: {
      include: [
        'field_image',
        'field_logo',
        'field_icon',
        'field_theme_palette.field_colors',
        'field_default_product_image',
        'field_privacy_policy_page',
        'field_home_page.field_page_paragraphs',
        'field_home_page.field_page_paragraphs.field_video.thumbnail',
        'field_home_page.field_page_paragraphs.field_images'
      ]
    }
  };

  static categoriesQuery: Query = {
    queryId: NgrxJsonApiQueries.queryIdCategories,
    type: ResourceTypes.storeSection,
    params: {
      fields: {
        ['node--store_section']: [
          'body',
          'title',
          'field_parent',
          'field_weight',
          'path',
          'status',
          'drupal_internal__nid',
          'field_unavailable',
          'category_product_list'
        ]
      },
      sorting: [
        {api: 'field_weight', direction: Direction.ASC}
      ]
    }
  };

  static informationsQuery: Query = {
    queryId: NgrxJsonApiQueries.queryIdPages,
    type: ResourceTypes.storePage,
    params: {
      sorting: [
        {api: 'field_weight', direction: Direction.ASC}
      ],
      include: [
        'field_page_paragraphs.field_video.thumbnail',
        'field_page_paragraphs.field_images',
        'field_page_paragraphs.field_products',
        'field_page_paragraphs.field_products.field_image',
        'field_page_paragraphs.field_products.variations'
      ]
    }
  };

  static messagesQuery: Query = {
    queryId: 'message',
    type: ResourceTypes.storeMessage,
    params: {
      sorting: [
        {api: 'changed', direction: Direction.DESC}
      ]
    }
  };

}

