import {Directive, ElementRef, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {fromEvent} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {go} from '../store/router/router.actions';

@UntilDestroy()
@Directive({
  selector: '[appDrupalRichTextLinks]'
})
export class DrupalRichTextLinksDirective implements OnInit {

  constructor(
    private el: ElementRef,
    private store: Store<any>
  ) {
  }

  ngOnInit(): void {
    fromEvent(this.el.nativeElement, 'click')
      .pipe(
        untilDestroyed(this),
        filter((clickEvent: MouseEvent) => {
          return (clickEvent.target as HTMLElement).tagName === 'A';
        }),
        tap((clickEvent: MouseEvent) => {
          clickEvent.preventDefault();
        }),
        map((clickEvent: MouseEvent) => {
          return (clickEvent.target as HTMLElement);
        })
      ).subscribe((target: HTMLLinkElement) => {
      const url: URL = new URL(target.href);
      this.store.dispatch(go({payload: {url: url.pathname}}));
    });
  }

}
