import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../environments/environment';
import * as fromLuneShop from './luneshop/reducers/lune-shop.reducer';
import * as fromProduct from '../snipcart/product/product.reducer';
import {routerReducer, RouterState} from "@ngrx/router-store";

export interface State {
  luneShop: fromLuneShop.State;
  products: fromProduct.State;
  router: RouterState;
}

export const reducers: ActionReducerMap<State> = {
  luneShop: null,
  products: null,
  router: routerReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
