import {ResourceIdentifier} from '@madeinlune/ngrx-json-api/src/interfaces';

export enum AlertLevels {
  informations = 'standard',
  warning = 'alert'
}

export type AlertLevel = AlertLevels.informations | AlertLevels.warning;

export enum AlertOptions {
  closeable = 'dismissible',
  sticky = 'sticky',
  forgettable = 'forgettable'
}

export type AlertOption = AlertOptions.closeable | AlertOptions.sticky | AlertOptions.forgettable;

export interface Alert extends ResourceIdentifier{

  id: string;
  title?: string;
  description: string;
  options?: AlertOption[];
  closeable?: boolean;
  level?: AlertLevel;
  forgettable?: boolean;
  displayInCheckOut?: boolean;
  weight: number;
  sticky?: boolean;

}

