import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromProduct from './product.reducer';
import {productsFeatureKey} from './product.reducer';

export interface State {
  products: fromProduct.State;
}

export const reducers: ActionReducerMap<State> = {
  products: fromProduct.reducer,
};

export const selectUserState = createFeatureSelector<fromProduct.State>(productsFeatureKey);

/*
export const selectUserIds = createSelector(
  selectUserState,
  fromUser.selectUserIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectUserEntities = createSelector(
  selectUserState,
  fromUser.selectUserEntities
);
 */

export const selectAllProducts = createSelector(
  selectUserState,
  fromProduct.selectAllProducts
);

/*
export const selectUserTotal = createSelector(
  selectUserState,
  fromUser.selectUserTotal
);
export const selectCurrentUserId = createSelector(
  selectUserState,
  fromUser.getSelectedUserId
);

export const selectCurrentUser = createSelector(
  selectUserEntities,
  selectCurrentUserId,
  (userEntities, userId) => userEntities[userId]
);
*/

