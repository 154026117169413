import {InjectionToken, Provider} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';


export const CONFIG_ACCOUNT_ID = new InjectionToken<Observable<string>>(
  'CONFIG_ACCOUNT_ID'
);

export const APP_CONFIG_PROVIDERS: Provider[] = [
  {
    provide: CONFIG_ACCOUNT_ID,
    deps: [AppConfigService],
    useFactory: configIdFactory
  }
];

export function configIdFactory(
  appConfigService: AppConfigService
): Observable<string> {
  return appConfigService.appConfig$.pipe(
    filter(appConfig => !!appConfig),
    map(appConfig => appConfig.account)
  );
}
