import {distinctUntilChanged} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { isEqual as _isEqual } from 'lodash';

export function loadashObjectIsEqual() {
  // tslint:disable-next-line:only-arrow-functions
  return function<T>(source: Observable<any>) {
    return source.pipe(
      distinctUntilChanged((oldValue, newValue) => {
        return _isEqual(oldValue, newValue);
      })
    );
  };
}
