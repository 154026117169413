import {ChangeDetectionStrategy, Component, Inject, NgModule, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {Store, StoreModule} from '@ngrx/store';
import * as fromAlerts from './alerts.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AlertsEffects} from './alerts.effects';
import {removeAlert} from './alerts.actions';
import {ALERTS, ALERTS_VISIBLE} from './alerts.providers';
import {Alert} from './alert.models';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AlertsService} from './alerts.service';
import {DrupalRouterModule} from '../../drupal-router/drupal-router.module';

@UntilDestroy()
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // the fade-in/fade-out animation.
    trigger('alertAnimation', [

      // the 'in' style determines the 'resting' state of the element when it is visible.
      state('show', style({height: '*'})),
      state('hide', style({height: '0px'})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({height: '0px'}),
        animate(400)
      ]),

      transition('* => show', [
        style({height: '0px'}),
        animate(400)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(400, style({height: '0px'}))),

      transition('* => hide',
        animate(400, style({height: '0px'})))
    ]),
  ]
})
export class AlertsComponent implements OnInit {

  constructor(
    @Inject(ALERTS) public alerts$: Observable<Alert[]>,
    @Inject(ALERTS_VISIBLE) public alertsVisible$: Observable<boolean>,
    private store: Store<any>,
    private alertsService: AlertsService
  ) {
  }

  ngOnInit(): void {
  }

  onRemoveAlert(alert: Alert) {
    this.store.dispatch(removeAlert({alert}));
  }

}

@NgModule({
  declarations: [
    AlertsComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    StoreModule.forFeature(fromAlerts.alertsFeatureKey, fromAlerts.reducer),
    EffectsModule.forFeature([AlertsEffects]),
    DrupalRouterModule
  ],
  exports: [AlertsComponent]
})

export class AlertsModule {

  constructor() {
  }

}
