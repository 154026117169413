import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';
import {ParagraphText} from '../model/paragraph-text';
import {DrupalBody} from '../model/drupal-body';

@Pipe({
  name: 'resourceToParagraphText'
})
export class ResourceToParagraphTextPipe implements PipeTransform {

  transform(resource: Resource): ParagraphText {
    if (resource) {
      const {id, type} = resource;
      const title: string = resource.attributes.field_header_title;
      const body: DrupalBody = resource.attributes.field_text_content;
      const paragraphText: ParagraphText = {id, type, title, body};
      return paragraphText;
    }

    return null;
  }

}
