import {createAction, props} from '@ngrx/store';
import {Payload} from 'ngrx-json-api';

export const checkLoadNextPage = createAction(
  '[NgrxJsonApi] Check load next page',
  props<{
    payload: Payload,
    zoneId: string
  }>()
);




