// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {Observable, throwError} from 'rxjs';

import {select, Store} from '@ngrx/store';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {CartMode} from '../model/cart-modes';
import * as fromLuneShop from '../store/luneshop/reducers/lune-shop.reducer';
import {getCartMode} from '../store/luneshop/selectors/lune-shop.selectors';
import {CartProduct} from '../snipcart/product/product.reducer';
import {selectAllProducts} from '../snipcart/product';

export interface ProductsQuantity {

  [id: string]: number;

}

export interface ProductsSku {

  [sku: string]: CartProduct;

}

export const CART_MODE = new InjectionToken<Observable<CartMode>>(
  'A stream with LuneShop Cart Mode'
);

export const CART_PRODUCTS = new InjectionToken<Observable<CartProduct[]>>(
  'A stream with Cart Products'
);

export const CART_PRODUCTS_BY_SKU = new InjectionToken<Observable<ProductsSku>>(
  'A stream with Cart Products by SKU'
);

export const CART_PRODUCTS_COUNT = new InjectionToken<Observable<number>>(
  'A stream with Cart Products Count'
);

export const CART_PRODUCTS_QUANTITY = new InjectionToken<Observable<ProductsQuantity>>(
  'A stream with Cart Products Count'
);

export const CART_PROVIDERS: Provider[] = [
  {
    provide: CART_MODE,
    deps: [Store],
    useFactory: cartModeFactory,
  },
  {
    provide: CART_PRODUCTS,
    deps: [Store],
    useFactory: cartProductsFactory,
  },
  {
    provide: CART_PRODUCTS_BY_SKU,
    deps: [Store],
    useFactory: cartProductsBySkuFactory,
  },
  {
    provide: CART_PRODUCTS_COUNT,
    deps: [Store],
    useFactory: cartProductsCountFactory,
  },
  {
    provide: CART_PRODUCTS_QUANTITY,
    deps: [Store],
    useFactory: cartProductsQuantityFactory,
  }
];

export function cartModeFactory(
  store: Store<fromLuneShop.State>
): Observable<CartMode> {
  return store.pipe(
    select(getCartMode),
    shareReplay(1)
  );
}

export function cartProductsFactory(
  store
): Observable<CartProduct[]> {
  return store.pipe(
    select(selectAllProducts),
    catchError(error => throwError(error)),
    shareReplay(1)
  );
}

export function cartProductsBySkuFactory(store): Observable<ProductsSku> {
  return cartProductsFactory(store).pipe(
    map(products => {
      const productsSku: ProductsSku = {};
      products.forEach((product) => {
        if (product?.resource?.attributes?.sku) {
          productsSku[product.resource.attributes.sku] = product;
        } else if (product?.id) {
          productsSku[product.id] = product;
        }
      });
      return productsSku;
    }),
    catchError(error => throwError(error)),
    shareReplay(1)
  );
}

export function cartProductsCountFactory(store): Observable<number> {
  return cartProductsFactory(store).pipe(
    map(products => {
      let count = 0;
      products.forEach((product) => {
        count = count + product.quantity;
      });
      return count;
    }),
    catchError(error => throwError(error)),
    shareReplay(1)
  );
}

export function cartProductsQuantityFactory(store): Observable<ProductsQuantity> {
  return cartProductsFactory(store).pipe(
    map(products => {
      const productsQuantity: ProductsQuantity = {};
      products.forEach((product) => {
        if (!!product?.resource?.id) {
          productsQuantity[product.resource.id] = product.quantity;
        } else if (product.id) {
          // product.id === sku
          productsQuantity[product.id] = product.quantity;
        }
      });
      return productsQuantity;
    }),
    catchError(error => throwError(error)),
    shareReplay(1)
  );
}
