// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';

import {select, Store} from '@ngrx/store';
import {filter, map, shareReplay, withLatestFrom} from 'rxjs/operators';
import * as fromLuneShop from '../store/luneshop/reducers/lune-shop.reducer';
import {
  getCategories,
  getDirectAccessPath,
  getDirectAccessResource,
  getDirectAccessResourceReady,
  getPages,
  getProductCategories,
  getSocialLinks,
  getStoreFront
} from '../store/luneshop/selectors/lune-shop.selectors';
import {CartProduct} from '../snipcart/product/product.reducer';
import {Link} from '../model/link';
import {LatinisePipe, OrderByPipe, SlugifyPipe} from 'ngx-pipes';
import {DelayedOrder, SocialNetwork, StoreFront} from '../model/store-front';
import {Image} from '../model/image';
import {StorePage} from '../model/store-page';
import {Category, ProductCategory} from '../model/product-category';
import {Product} from '../model/product';
import {Alert} from '../components/alerts/alert.models';
import {ALERTS} from '../components/alerts/alerts.providers';
import {MailChimpParagraph} from '../components/mailchimp-subscribe-form/mailchimp-subscribe-form.component';
import {loadashArrayDifferenceWith} from '../rxjs-operators';

export type BoutiqueClosedMode = 'normal' | 'unavailable';

export interface ProductsQuantity {

  [id: string]: number;

}

export interface ProductsSku {

  [sku: string]: CartProduct;

}

export const STORE_FRONT = new InjectionToken<Observable<StoreFront>>(
  'A stream with storeFront Node'
);

export const SOCIAL_LINKS = new InjectionToken<Observable<Link[]>>(
  'A stream with Node Social Links'
);

export const PAGES = new InjectionToken<Observable<StorePage[]>>(
  'A stream with Pages'
);

export const PAGES_BY_PATH = new InjectionToken<Observable<{ [path: string]: StorePage }>>(
  'A stream with Pages by path'
);

export const HAS_PAGES = new InjectionToken<Observable<boolean>>(
  'A stream with has Pages or not'
);

export const CATEGORIES = new InjectionToken<Observable<Category[]>>(
  'A stream with Categories (used for Categories Menu)'
);

export const PRODUCT_CATEGORIES = new InjectionToken<Observable<ProductCategory[]>>(
  'A stream with Product Categories (Categories with products inside, used for Catalog)'
);

export const HAS_CORPORATE_CONTENT = new InjectionToken<Observable<boolean>>(
  'A stream with has Pages or Social Links'
);

export const PRODUCT_DEFAULT_IMAGE = new InjectionToken<Observable<Image>>(
  'A stream with with default product Image'
);

export const CLIENT_NAME = new InjectionToken<Observable<string>>(
  'CLIENT_NAME'
);

export const CLIENT_LOGO = new InjectionToken<Observable<Image>>(
  'A stream with client Logo'
);

export const CLIENT_APP_ICON = new InjectionToken<Observable<string>>(
  'A stream with client App Icon'
);

export const CLIENT_WELCOME_IMAGE = new InjectionToken<Observable<Image>>(
  'A stream with client Welcome Image'
);

export const CLIENT_TITLE = new InjectionToken<Observable<string>>(
  'A stream with client Title, used as prefix in app Title'
);

export const CLIENT_SLOGAN = new InjectionToken<Observable<string>>(
  'CLIENT_SLOGAN'
);

export const PRIVACY_NOTICE = new InjectionToken<Observable<string>>(
  'A stream with the privacy notice'
);

export const ENABLE_DETAIL_VIEW = new InjectionToken<Observable<boolean>>(
  'A stream the property which shows or hides the detail View'
);

export const DIRECT_ACCESS_RESOURCE = new InjectionToken<Observable<ProductCategory | Product>>(
  'A stream the direct access Resource'
);

export const DIRECT_ACCESS_RESOURCE_READY = new InjectionToken<Observable<boolean>>(
  'A stream the direct access Resource is Ready'
);

export const DIRECT_ACCESS_PATH = new InjectionToken<Observable<string>>(
  'A stream the direct access Path'
);

export const HOME_PATH = new InjectionToken<Observable<string>>(
  'A stream path of the Home'
);

export const ANALYTICS_ACCOUNT_ID = new InjectionToken<Observable<string>>(
  'A stream with the Analytics ID'
);

export const PROMOTED_PAGES = new InjectionToken<Observable<StorePage[]>>(
  'PROMOTED_PAGES'
);

export const MAILCHIMP_PARAGRAPH_DEFAULT = new InjectionToken<Observable<MailChimpParagraph>>(
  'MAILCHIMP_PARAGRAPH_DEFAULT'
);

export const IS_BOUTIQUE_CLOSED = new InjectionToken<Observable<boolean>>(
  'IS_BOUTIQUE_CLOSED'
);

export const BOUTIQUE_CLOSED_MODE = new InjectionToken<Observable<BoutiqueClosedMode>>(
  'BOUTIQUE_CLOSED_MODE'
);
export const HAS_ALERTS = new InjectionToken<Observable<boolean>>(
  'HAS_ALERTS'
);

export const HAS_STICKY_ALERT = new InjectionToken<Observable<boolean>>(
  'HAS_STICKY_ALERT'
);

export const SHAREABLE_SOCIAL_NETWORKS = new InjectionToken<Observable<string[]>>(
  'SHAREABLE_SOCIAL_NETWORKS'
);

export const CART_MESSAGE = new InjectionToken<Observable<string>>(
  'CART_MESSAGE'
);

export const SHOW_DESCRIPTION_PREVIEW = new InjectionToken<Observable<boolean>>(
  'SHOW_DESCRIPTION_PREVIEW'
);

export const MAIN_DOMAIN = new InjectionToken<Observable<string>>(
  'MAIN_DOMAIN'
);

export const DELAYED_ORDER_CONFIGURATION = new InjectionToken<Observable<DelayedOrder>>(
  'DELAYED_ORDER_CONFIGURATION'
);

export const ENABLE_PICK_UP_DATE = new InjectionToken<Observable<boolean>>(
  'ENABLE_PICK_UP_DATE'
);

export const STORE_FRONT_PROVIDERS: Provider[] = [
  {
    provide: STORE_FRONT,
    deps: [Store],
    useFactory: storeFrontFactory
  },
  {
    provide: ANALYTICS_ACCOUNT_ID,
    deps: [STORE_FRONT],
    useFactory: annalyticsAccountIdFactory
  },
  {
    provide: HOME_PATH,
    deps: [STORE_FRONT],
    useFactory: homePathFactory
  },
  {
    provide: PRIVACY_NOTICE,
    deps: [STORE_FRONT],
    useFactory: privacyNoticeFactory
  },
  {
    provide: SOCIAL_LINKS,
    deps: [Store],
    useFactory: socialLinksFactory
  },
  {
    provide: SHAREABLE_SOCIAL_NETWORKS,
    deps: [STORE_FRONT],
    useFactory: shareableSocialNetworksFactory
  },
  {
    provide: PAGES,
    deps: [STORE_FRONT, Store, OrderByPipe],
    useFactory: pagesFactory
  },
  {
    provide: HAS_CORPORATE_CONTENT,
    deps: [HAS_PAGES, SOCIAL_LINKS],
    useFactory: hasCorporateContentFactory
  },
  {
    provide: DIRECT_ACCESS_RESOURCE,
    deps: [Store],
    useFactory: setDirectAccessResourceFactory
  },
  {
    provide: DIRECT_ACCESS_RESOURCE_READY,
    deps: [Store],
    useFactory: setDirectAccessResourceReadyFactory
  },
  {
    provide: DIRECT_ACCESS_PATH,
    deps: [Store],
    useFactory: setDirectAccessPathFactory
  },
  {
    provide: CATEGORIES,
    deps: [Store],
    useFactory: categoriesFactory
  },
  {
    provide: PRODUCT_CATEGORIES,
    deps: [Store],
    useFactory: productCategoriesFactory
  },
  {
    provide: PAGES_BY_PATH,
    deps: [PAGES, SlugifyPipe, LatinisePipe],
    useFactory: pagesByPathFactory
  },
  {
    provide: HAS_PAGES,
    deps: [PAGES],
    useFactory: hasPagesFactory
  },
  {
    provide: PRODUCT_DEFAULT_IMAGE,
    deps: [STORE_FRONT],
    useFactory: defaultProductImageFactory
  },
  {
    provide: CART_MESSAGE,
    deps: [STORE_FRONT],
    useFactory: cartMessageFactory
  },
  {
    provide: CLIENT_LOGO,
    deps: [STORE_FRONT],
    useFactory: clientLogoFactory
  },
  {
    provide: CLIENT_WELCOME_IMAGE,
    deps: [STORE_FRONT],
    useFactory: clientWelcomeImageFactory
  },
  {
    provide: CLIENT_APP_ICON,
    deps: [STORE_FRONT],
    useFactory: clientAppIconFactory
  },
  {
    provide: CLIENT_NAME,
    deps: [STORE_FRONT],
    useFactory: clientNameFactory
  },
  {
    provide: CLIENT_TITLE,
    deps: [STORE_FRONT],
    useFactory: clientTitleFactory
  },
  {
    provide: CLIENT_SLOGAN,
    deps: [STORE_FRONT],
    useFactory: clientSloganFactory
  },
  {
    provide: ENABLE_DETAIL_VIEW,
    deps: [STORE_FRONT],
    useFactory: enableDetailViewFactory
  },
  {
    provide: IS_BOUTIQUE_CLOSED,
    deps: [STORE_FRONT],
    useFactory: isBoutiqueClosed
  },
  {
    provide: BOUTIQUE_CLOSED_MODE,
    useFactory: boutiqueClosedModeFactory
  },
  {
    provide: MAILCHIMP_PARAGRAPH_DEFAULT,
    deps: [STORE_FRONT],
    useFactory: mailchimpParagraphDefaultFactory
  },
  {
    provide: PROMOTED_PAGES,
    deps: [PAGES],
    useFactory: promotedPagesFactory
  },
  {
    provide: HAS_ALERTS,
    deps: [ALERTS],
    useFactory: hasAlertsFactory
  },
  {
    provide: HAS_STICKY_ALERT,
    deps: [ALERTS],
    useFactory: hasStickyAlertFactory
  },
  {
    provide: SHOW_DESCRIPTION_PREVIEW,
    deps: [STORE_FRONT],
    useFactory: showDescriptionPreviewFactory
  },
  {
    provide: MAIN_DOMAIN,
    deps: [STORE_FRONT],
    useFactory: (storeFront$: Observable<StoreFront>): Observable<string> => {
      return storeFront$.pipe(
        map(storeFront => storeFront?.mainDomain)
      );
    }
  },
  {
    provide: ENABLE_PICK_UP_DATE,
    deps: [DELAYED_ORDER_CONFIGURATION],
    useFactory: (delayedOrder$: Observable<DelayedOrder>): Observable<boolean> => {
      return delayedOrder$.pipe(
        map(delayedOrder => delayedOrder.enabled)
      );
    }
  },
  {
    provide: DELAYED_ORDER_CONFIGURATION,
    deps: [STORE_FRONT],
    useFactory: (storeFront$: Observable<StoreFront>): Observable<DelayedOrder> => {
      return storeFront$.pipe(
        map(storeFront => storeFront?.delayedOrder)
      );
    }
  }
];

export function mailchimpParagraphDefaultFactory(
  storeFront$: Observable<StoreFront>
): Observable<MailChimpParagraph> {
  return storeFront$.pipe(
    filter(storeFront => !!storeFront),
    map(storeFront => {
        const mailChimpParagraph: MailChimpParagraph = {
          legal: 'En renseignant votre adresse email, vous acceptez de recevoir régulièrement notre actualité et nos promotions par courrier électronique et vous prenez connaissance de notre Politique de confidentialité.\n' +
            'Vous pouvez vous désinscrire à tout moment à l’aide des liens de désincription.',
          service: 'Nous utilisons Mailchimp comme plate-forme marketing. En cliquant ci-dessus pour vous abonner, vous reconnaissez que vos informations seront transférées à Mailchimp pour traitement.',
          config: {
            clientId: storeFront.mailchimpAccountId,
            clientName: storeFront.mailchimpAccountName,
            serverPrefix: storeFront.mailchimpAccountServerPrefix
          },
        };
        return mailChimpParagraph;
      }
    )
  );
}

export function showDescriptionPreviewFactory(
  storeFront$: Observable<StoreFront>
): Observable<boolean> {
  return storeFront$.pipe(
    filter(storeFront => !!storeFront),
    map(storeFront => {
        return storeFront.showDescriptionPreview;
      }
    )
  );
}


export function clientLogoFactory(
  storeFront$: Observable<StoreFront>
): Observable<Image> {
  return storeFront$.pipe(
    filter(storeFront => !!storeFront),
    map(storeFront => storeFront.logo)
  );
}

export function clientWelcomeImageFactory(
  storeFront$: Observable<StoreFront>
): Observable<Image> {
  return storeFront$.pipe(
    filter(storeFront => !!storeFront),
    map(storeFront => storeFront.welcomeImage)
  );
}

export function clientAppIconFactory(
  storeFront$: Observable<StoreFront>
): Observable<string> {
  return storeFront$.pipe(
    filter(storeFront => !!storeFront),
    map(storeFront => storeFront.appIcons['180'].href)
  );
}

export function cartMessageFactory(
  storeFront$: Observable<StoreFront>
): Observable<string> {
  return storeFront$.pipe(
    filter(storeFront => !!storeFront),
    map(storeFront => storeFront.snipcartCustomBasketInfo)
  );
}

export function privacyNoticeFactory(
  storeFront$: Observable<StoreFront>
): Observable<string> {
  return storeFront$.pipe(
    filter(storeFront => !!storeFront),
    map(storeFront => storeFront.privacyPolicyContent)
  );
}

export function clientNameFactory(
  storeFront$: Observable<StoreFront>
): Observable<string> {
  return storeFront$.pipe(
    map(storeFront => storeFront ? storeFront.title : '')
  );
}

export function clientTitleFactory(
  storeFront$: Observable<StoreFront>
): Observable<string> {
  return storeFront$.pipe(
    map(storeFront => storeFront ? storeFront.title : '')
  );
}

export function clientSloganFactory(
  storeFront$: Observable<StoreFront>
): Observable<string> {
  return storeFront$.pipe(
    map(storeFront => storeFront ? storeFront.slogan : '')
  );
}

export function annalyticsAccountIdFactory(
  storeFront$: Observable<StoreFront>
): Observable<string> {
  return storeFront$.pipe(
    map(storeFront => storeFront.googleAnalyticsId)
  );
}

export function enableDetailViewFactory(
  storeFront$: Observable<StoreFront>
): Observable<boolean> {
  return storeFront$.pipe(
    filter(storeFront => !!storeFront),
    map(storeFront => storeFront.enableDetailView)
  );
}

export function homePathFactory(
  storeFront$: Observable<StoreFront>
): Observable<string> {
  return storeFront$.pipe(
    filter(storeFront => !!storeFront),
    map(storeFront => {
      return storeFront.ngPath + '#appWelcome';
    })
  );
}

export function defaultProductImageFactory(
  storeFront$: Observable<StoreFront>
): Observable<Image> {
  return storeFront$.pipe(
    map(storeFront => storeFront?.defaultProductImage)
  );
}

export function storeFrontFactory(
  store: Store<fromLuneShop.State>
): Observable<StoreFront> {
  return store.pipe(
    select(getStoreFront),
    shareReplay(1)
  );
}

export function socialLinksFactory(
  store: Store<fromLuneShop.State>
): Observable<Link[]> {
  return store.pipe(
    select(getSocialLinks),
    shareReplay(1)
  );
}

export function shareableSocialNetworksFactory(
  storeFront$: Observable<StoreFront>,
): Observable<SocialNetwork[]> {
  return storeFront$.pipe(
    map(storeFront => storeFront.shareableSocialNetworks),
    shareReplay(1)
  );
}

export function pagesFactory(
  storeFront$: Observable<StoreFront>,
  store: Store<fromLuneShop.State>,
  orderByPipe: OrderByPipe
): Observable<StorePage[]> {
  return store.pipe(
    select(getPages),
    withLatestFrom(storeFront$),
    map(([pages, storeFront]) => {
      const homePage: StorePage = storeFront.homePage;
      if (homePage) {
        return orderByPipe.transform(pages, 'weight')?.filter(page => page.id !== homePage.id);
      }
      return orderByPipe.transform(pages, 'weight');
    }),
    shareReplay(1)
  );
}

export function setDirectAccessPathFactory(
  store: Store<fromLuneShop.State>
): Observable<string> {
  return store.pipe(
    select(getDirectAccessPath),
    shareReplay(1)
  );
}

export function setDirectAccessResourceFactory(
  store: Store<fromLuneShop.State>
): Observable<ProductCategory | Product> {
  return store.pipe(
    select(getDirectAccessResource),
    shareReplay(
      1)
  );
}

export function setDirectAccessResourceReadyFactory(
  store: Store<fromLuneShop.State>
): Observable<boolean> {
  return store.pipe(
    select(getDirectAccessResourceReady)
  );
}

export function hasCorporateContentFactory(
  hasPages$: Observable<boolean>,
  socialLinks$: Observable<Link[]>
) {
  return combineLatest([hasPages$, socialLinks$])
    .pipe(
      map(([hasPages, socialLinks]) => {
        return hasPages || socialLinks?.length > 0;
      })
    );
}

export function categoriesFactory(
  store: Store<fromLuneShop.State>
): Observable<Category[]> {
  return store.pipe(
    select(getCategories),
    shareReplay(1)
  );
}

export function productCategoriesFactory(
  store: Store<fromLuneShop.State>
): Observable<ProductCategory[]> {
  return store.pipe(
    select(getProductCategories),
    shareReplay(1)
  );
}

export function pagesByPathFactory(
  pages$: Observable<StorePage[]>,
  slugifyPipe: SlugifyPipe,
  latinisePipe: LatinisePipe
): Observable<{ [path: string]: StorePage }> {
  return pages$
    .pipe(
      filter(pages => !!pages),
      map(pages => {
        const pagesMap: { [path: string]: StorePage } = {};
        pages.forEach(page => {
          pagesMap[page.path] = page;
        });
        return pagesMap;
      }),
      shareReplay(1)
    );
}

export function hasPagesFactory(
  pages$: Observable<StorePage[]>
): Observable<boolean> {
  return pages$.pipe(
    map(pages => pages?.length > 0),
    shareReplay(1)
  );
}

export function isBoutiqueClosed(
  storeFront$: Observable<StoreFront>
): Observable<boolean> {
  return storeFront$.pipe(
    map(
      storeFront => storeFront.unavailable
    )
  );
}

export function boutiqueClosedModeFactory(): Observable<BoutiqueClosedMode> {
  return of('normal');
}

export function promotedPagesFactory(
  pages$: Observable<StorePage[]>
): Observable<StorePage[]> {
  return pages$
    .pipe(
      map(pages => {
        return pages?.filter(page => {
          return page.promote === true;
        });
      }),
      loadashArrayDifferenceWith()
    );
}

export function hasAlertsFactory(
  alerts$: Observable<Alert[]>
): Observable<boolean> {
  return alerts$.pipe(
    map(alerts => alerts?.length > 0)
  );
}

export function hasStickyAlertFactory(
  alerts$: Observable<Alert[]>
): Observable<boolean> {
  return alerts$.pipe(
    map(alerts => alerts?.filter(alert => alert?.sticky).length > 0)
  );
}
