import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, Optional, TemplateRef} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {LuneWindowData, WindowAction, WindowPassport} from '../window.model';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';
import {WindowService} from '../window.service';
import {Store} from '@ngrx/store';
import {TemplatesService} from "../../core/services/templates.service";

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowComponent implements OnInit, AfterViewInit {

  passport$: ReplaySubject<WindowPassport> = new ReplaySubject<WindowPassport>(1);

  contentTemplate$: Observable<TemplateRef<any>>;

  actions$: Observable<WindowAction[]>;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public windowData: LuneWindowData,
    private templatesService: TemplatesService,
    public windowService: WindowService,
    private store: Store<any>
  ) {
  }

  ngOnInit(): void {

    if (!this.windowData.passport) {
      // used to manage old school way of managing window with dynamic content (ie: Made in Lune File Widget)
      this.windowData.passport = {};
    }

    this.passport$.next(this.windowData.passport);

    this.actions$ = this.passport$.pipe(
      map(passport => passport?.actions)
    );

    this.contentTemplate$ = this.passport$.pipe(
      filter(passport => !!passport),
      filter(passport => !!passport.components.content.template),
      mergeMap(passport => {
        return this.templatesService.getTemplate$(passport.components.content.template);
      })
    );

  }

  ngAfterViewInit() {

  }

  onActionClick(action: WindowAction) {
    if (action.emitter) {
      action.emitter.emit(action.id);
    }
    if (action.action) {
      this.store.dispatch({type: action.action.type, data: action.action.data});
    }
  }

}
