// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {Alert} from './alert.models';
import {select, Store} from '@ngrx/store';
import * as fromAlerts from './alerts.reducer';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {alertsVisible, getAlerts} from './alerts.selectors';

export const ALERTS = new InjectionToken<Alert[]>(
  'ALERTS'
);

export const ALERTS_VISIBLE = new InjectionToken<boolean>(
  'ALERTS_VISIBLE'
);

export const ALERTS_PROVIDERS: Provider[] = [
  {
    provide: ALERTS,
    deps: [Store],
    useFactory: alertsFactory
  },
  {
    provide: ALERTS_VISIBLE,
    deps: [Store],
    useFactory: alertsVisibleFactory
  }
];

export function alertsFactory(
  store: Store<fromAlerts.State>
): Observable<Alert[]> {
  return store.pipe(
    select(getAlerts),
    shareReplay(1)
  );
}

export function alertsVisibleFactory(
  store: Store<fromAlerts.State>
): Observable<boolean> {
  return store.pipe(
    select(alertsVisible),
    shareReplay(1)
  );
}
