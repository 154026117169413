// token to access a stream with the information you need
import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductCategory} from '../../model/product-category';
import {ProductsGroup} from '../../store/catalog-product/catalog-product.reducer';
import {Image} from '../../model/image';

export interface MenuNavigatorShortcut {
  title: string;
  icon?: Image;
}

export type MenuNavigatorState = 'elsewhere' | 'detail';

export const MENUS_NAVIGATOR_STATE = new InjectionToken<Observable<MenuNavigatorState>>(
  'A stream the menus navigator state'
);

export const CURRENT_CATEGORY_MENU = new InjectionToken<Observable<ProductCategory>>(
  'A stream the current category'
);

export const CURRENT_PRODUCTS_GROUP = new InjectionToken<Observable<ProductsGroup>>(
  'CURRENT_PRODUCTS_GROUP'
);

export const MENU_NAVIGATOR_SHORTCUT = new InjectionToken<Observable<MenuNavigatorShortcut>>(
  'MENU_NAVIGATOR_SHORTCUT'
);
