<mat-dialog-content class="d-flex flex-fill overflow-hidden">
  <div class="d-flex flex-column overflow-hidden flex-fill position-relative">

    <ng-container *ngIf="contentTemplate$|async as contentTemplate">
      <ng-template
        [ngTemplateOutlet]="contentTemplate">
      </ng-template>
    </ng-container>

    <ng-container *ngIf="contentComponent$|async as contentComponent">
      <ng-template
        [ngComponentOutlet]="contentComponent">
      </ng-template>
    </ng-container>

  </div>
</mat-dialog-content>
