import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as ProductActions from './product.actions';
import {Resource} from 'ngrx-json-api';

export class CartProduct {

  resource?: Resource;
  id: string;
  uuid: string;
  quantity: number;
  addedOn: number;

}

export const productsFeatureKey = 'cart';

export interface State extends EntityState<CartProduct> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CartProduct> = createEntityAdapter<CartProduct>({
  sortComparer: sortByAddedOn
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function sortByAddedOn(a: CartProduct, b: CartProduct): number {
  return a.addedOn - b.addedOn;
}

const productReducer = createReducer(
  initialState,
  on(ProductActions.addProduct,
    (state, action) => adapter.addOne(action.product, state)
  ),
  on(ProductActions.upsertProduct,
    (state, action) => adapter.upsertOne(action.product, state)
  ),
  on(ProductActions.addProducts,
    (state, action) => adapter.addMany(action.products, state)
  ),
  on(ProductActions.upsertProducts,
    (state, action) => adapter.upsertMany(action.products, state)
  ),
  on(ProductActions.updateProduct,
    (state, action) => adapter.updateOne(action.product, state)
  ),
  on(ProductActions.updateProducts,
    (state, action) => adapter.updateMany(action.products, state)
  ),
  on(ProductActions.deleteProduct,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProductActions.deleteProducts,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProductActions.loadProducts,
    (state, action) => adapter.setAll(action.products, state)
  ),
  on(ProductActions.clearProducts,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return productReducer(state, action);
}


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectAllProducts = selectAll;
