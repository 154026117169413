import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';
import {ParagraphProducts} from '../model/paragraph-products';
import {ResourceIdentifier} from '@madeinlune/ngrx-json-api/src/interfaces';

@Pipe({
  name: 'resourceToParagraphProducts'
})
export class ResourceToParagraphProductsPipe implements PipeTransform {

  transform(resource: Resource): ParagraphProducts {
    if (resource) {
      const {id, type} = resource;
      const title: string = resource.attributes.field_header_title;
      const products: ResourceIdentifier[] = resource.relationships.field_products.data;
      const paragraphProducts: ParagraphProducts = {id, type, title, products};
      return paragraphProducts;
    }

    return null;
  }

}
