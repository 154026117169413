import {createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as CatalogCategoryActions from './catalog-category.actions';
import {Category} from '../../model/product-category';

export const catalogCategoriesFeatureKey = 'catalogCategories';

export interface State extends EntityState<Category> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(CatalogCategoryActions.addCatalogCategory,
    (state, action) => adapter.addOne(action.category, state)
  ),
  on(CatalogCategoryActions.upsertCatalogCategory,
    (state, action) => adapter.upsertOne(action.category, state)
  ),
  on(CatalogCategoryActions.addCatalogCategories,
    (state, action) => adapter.addMany(action.categories, state)
  ),
  on(CatalogCategoryActions.upsertCatalogCategories,
    (state, action) => adapter.upsertMany(action.categories, state)
  ),
  on(CatalogCategoryActions.updateCatalogCategory,
    (state, action) => adapter.updateOne(action.category, state)
  ),
  on(CatalogCategoryActions.updateCatalogCategories,
    (state, action) => adapter.updateMany(action.categories, state)
  ),
  on(CatalogCategoryActions.deleteCatalogCategory,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(CatalogCategoryActions.deleteCatalogCategories,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(CatalogCategoryActions.loadCatalogCategories,
    (state, action) => adapter.setAll(action.categories, state)
  ),
  on(CatalogCategoryActions.clearCatalogCategories,
    state => adapter.removeAll(state)
  ),
);

export const selectCatalogCategoryState = createFeatureSelector<State>(catalogCategoriesFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectAllCategories = createSelector(
  selectCatalogCategoryState,
  selectAll
);

export const selectCategoryIds = createSelector(
  selectCatalogCategoryState,
  selectIds
);

export const selectCategoryEntities = createSelector(
  selectCatalogCategoryState,
  selectEntities
);

export const selectCategoryById = (id: string) => createSelector(
  selectCategoryEntities,
  (categories) => categories[id] ? categories[id] : null
);

export const selectCategoryProductIds = (id: string) => createSelector(
  selectCategoryById(id),
  (category) => {
    return category?.products;
  }
);
