import {Compiler, Injectable, Injector, NgModuleFactory, Type} from '@angular/core';


// https://juristr.com/blog/2019/10/lazyload-module-ivy-viewengine/

@Injectable({
  providedIn: 'root'
})
export class LazyLoaderService {

  moduleCache: any = {};

  constructor(private compiler: Compiler, private injector: Injector) {
  }

  loadModule(path: any) {
    if (!this.moduleCache[path]) {
      (path() as Promise<NgModuleFactory<any> | Type<any>>)
        .then(elementModuleOrFactory => {
          if (elementModuleOrFactory instanceof NgModuleFactory) {
            // if ViewEngine
            return elementModuleOrFactory;
          } else {
            try {
              // if Ivy
              return this.compiler.compileModuleAsync(elementModuleOrFactory);
            } catch (err) {
              throw err;
            }
          }
        })
        .then(moduleFactory => {
          try {
            const elementModuleRef = moduleFactory.create(this.injector);
            this.moduleCache[path] = elementModuleRef;
          } catch (err) {
            throw err;
          }
        });
    }
  }
}
