import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartMessageService {

  componentAdded: boolean;
  componentRemoved: boolean;

  constructor() {
  }
}
