import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as AnalyticsActions from './analytics.actions';
import {filter, tap, withLatestFrom} from 'rxjs/operators';
import {Angulartics2GoogleGlobalSiteTag} from 'angulartics2/gst';
import {Angulartics2} from 'angulartics2';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {STORE_FRONT} from '../providers/storeFront.providers';
import {StoreFront} from '../model/store-front';
import {Observable} from 'rxjs';
import {WINDOW} from '@ng-web-apis/common';
import {isScullyRunning} from '@scullyio/ng-lib';

declare var gtag: any;

@Injectable()
export class AnalyticsEffects {


  initializeAnalytics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalyticsActions.initializeAnalytics),
      filter(() => !isScullyRunning()),
      tap(action => {
        const script = this.window.document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js';
        this.window.document.getElementsByTagName('head')[0].appendChild(script);
        this.window['dataLayer'] = this.window['dataLayer'] || [];
        gtag('js', new Date());
        this.angulartics2.settings.gst = {
          ...this.angulartics2.settings.gst,
          trackingIds: [
            action.accountId,
          ],
          anonymizeIp: true,
        };
      })
    );
  }, {dispatch: false});

  initializeGTM$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalyticsActions.initializeGTM),
      tap(action => {
        const gtmHeaderScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${action.containerId}');`;
        const headScript = this.window.document.createElement('script');
        headScript.innerText = gtmHeaderScript;
        this.window.document.getElementsByTagName('head')[0].appendChild(headScript);
      })
    );
  }, {dispatch: false});

  startTracking$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalyticsActions.startTracking),
      withLatestFrom(this.storeFront),
      tap(([action, storeFront]) => {
        if (storeFront.googleAnalyticsId) {
          this.googleAnalyticsGST.startTracking();
        }
        if (storeFront.gtmContainerId) {
          this.googleAnalyticsGTM.startTracking();
        }
      })
    );
  }, {dispatch: false});

  stopTracking$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalyticsActions.stopTracking),
      tap(action => {
      })
    );
  }, {dispatch: false});


  constructor(
    private actions$: Actions,
    @Inject(STORE_FRONT) protected storeFront: Observable<StoreFront>,
    protected googleAnalyticsGST: Angulartics2GoogleGlobalSiteTag,
    protected googleAnalyticsGTM: Angulartics2GoogleTagManager,
    protected angulartics2: Angulartics2,
    @Inject(WINDOW) protected window: Window,
    @Inject(PLATFORM_ID) protected platformId,
  ) {
  }

}
