import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {StorePage, StorePageParagraph} from '../model/store-page';
import {Resource} from 'ngrx-json-api';
import {DrupalBody} from '../model/drupal-body';
import {TitleToPathPipe} from './title-to-path.pipe';
import {ResourcesToParagraphsPipe} from './resources-to-paragraphs.pipe';
import {ShortenPipe, StripTagsPipe} from 'ngx-pipes';
import {AppConfigService} from '../config/app-config.service';

@Pipe({
  name: 'resourceToStorePage'
})
export class ResourceToStorePagePipe implements PipeTransform {

  constructor(
    private titleToPathPipe: TitleToPathPipe,
    private resourcesToParagraphsPipe: ResourcesToParagraphsPipe,
    private stripTagsPipe: StripTagsPipe,
    private shortenPipe: ShortenPipe,
    private config: AppConfigService,
  ) {
  }

  transform(resource: Resource): Observable<StorePage> {
    if (resource) {

      const {id, type} = resource;
      const title: string = resource.attributes.title;
      const body: DrupalBody = resource.attributes.body;
      const weight: number = resource.attributes.field_weight;
      const promote: boolean = resource.attributes.promote;
      // const language: string = resource.attributes.langcode;
      const language = 'fr';
      let pathAlias: string = resource.attributes?.path?.alias;
      if (pathAlias?.indexOf(this.config.getConfig().account) === 1) {
        pathAlias = pathAlias.substring(this.config.getConfig().account.length + 1);
      }
      const basePath = '/node/' + resource.attributes.drupal_internal__nid;
      const path: string = (pathAlias ? pathAlias : basePath);
      const ngPath: string = '/pages/' + id;

      const paragraphs: StorePageParagraph[] = this.resourcesToParagraphsPipe
        .transform(resource?.relationships?.field_page_paragraphs?.reference?.filter((item) => !!item));

      const descriptionPlainText: string = body?.summary ? body?.summary : (body?.processed) ? this.stripTagsPipe.transform(body.processed) : null;
      const metaDescription: string = descriptionPlainText ? this.shortenPipe
        .transform(descriptionPlainText, 160, '...', false) : null;

      const fragment: string = this.titleToPathPipe.transform(resource.attributes.title);

      const storePage: StorePage = {
        id,
        type,
        weight,
        title,
        body,
        path,
        ngPath,
        fragment,
        paragraphs,
        language,
        metaDescription,
        promote
      };

      return of(storePage);

    }

    return of(null);
  }

}
