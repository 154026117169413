import {createSelector} from '@ngrx/store';
import * as fromLuneShop from '../reducers/lune-shop.reducer';
import {Link} from '../../../model/link';
import {StoreFront} from '../../../model/store-front';
import {ProductCategory} from '../../../model/product-category';

export const selectLuneShopState = createSelector((state: any) => state.luneShop, value => value);

export const getCartMode = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state.cartMode
);

export const getStoreFront = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.storeFront
);

export const getPages = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state.pages
);

export const getDirectAccessPath = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state.directAccessResource.path
);

export const getDirectAccessResource = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state.directAccessResource.resource
);

export const getDirectAccessResourceReady = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state.directAccessResource.ready === true
);

export const getCategories = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state.categories
);

export const getProductCategories = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state.categoriesProducts
);

export const getProductCategoriesLoadingQueue = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.categoriesLoadingQueue
);

export const getSocialLinks = createSelector(
  getStoreFront,
  (storeFront: StoreFront): Link[] => {
    if (!!storeFront) {
      return [
        storeFront.instagram,
        storeFront.facebook,
        storeFront.pinterest
      ].filter(link => !!link);
    } else {
      return [];
    }
  }
);

export const getCategoriesProducts = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.categoriesProducts
);

export const getCategoriesProductsMap = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.categoriesProductsMap
);

export const getCategoryById = (id: string) => createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.categories?.filter(category => category.id === id).shift()
);

export const getProductById = (id: string) => createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.productsMap && state?.productsMap[id]
);

export const getPageById = (id: string) => createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => {
    if (Array.isArray(state?.pages)) {
      return state?.pages.filter(page => page.id === id).shift();
    }
    return null;
  }
);

export const getProductsByCategoryId = (id: string) => createSelector(
  getCategoriesProducts,
  (productCategories: ProductCategory[]) => {
    const targetProductCategory: ProductCategory = productCategories.filter(productCategory => productCategory.id === id).shift();
    return targetProductCategory?.products;
  }
);


export const getHistory = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.history
);

export const getPathInfos = (path: string) => createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => {
    return state?.pathInfosMap && state?.pathInfosMap[path];
  }
);

export const getCategoryMenu = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.categoryMenu
);

export const getCatalogLoaded = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.catalogLoaded
);

export const getContentInViewport = createSelector(
  selectLuneShopState,
  (state: fromLuneShop.State) => state?.contentInViewport
);
