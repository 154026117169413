import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {switchMap} from 'rxjs/operators';
import {Action} from '@ngrx/store';
import {ApiGetInitAction, Query} from 'ngrx-json-api';
import {Resource} from '@madeinlune/ngrx-json-api/src/interfaces';
import {checkLoadNextPage} from './ngrx-json-api.actions';

@Injectable()
export class NgrxJsonApiEffects {

  loadNextPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkLoadNextPage),
        switchMap((action) => {

          const actions: Action[] = [];
          const query: Query = action.payload.query;
          const data: Resource[] = action.payload.jsonApiData.data;
          const meta: { count: number | string } = action.payload.jsonApiData.meta;
          const links: { last: string, next: string, self: string } = action.payload.jsonApiData.links;

          if (links.next) {
            query.params.offset = data.length;
            actions.push(new ApiGetInitAction(query, action.zoneId));
          }

          return actions;

        })
      )
  );

  constructor(
    private actions$: Actions
  ) {
  }

}
