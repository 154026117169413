import {HttpClient} from '@angular/common/http';
import {TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoLoader, TranslocoModule} from '@ngneat/transloco';
import {Inject, Injectable, NgModule, PLATFORM_ID} from '@angular/core';
import {environment} from '../../environments/environment';
import {catchError, map, mergeMap} from 'rxjs/operators';
import xliff from 'xliff';
import {fromPromise} from 'rxjs/internal-compatibility';
import {of} from 'rxjs';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {

  private translocoStateKey: StateKey<any> = makeStateKey<any>('translocoStateKey');

  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  // https://stackoverflow.com/questions/52106505/xml2js-in-angular-6-cant-resolve-stream-and-timers/55788098#55788098
  getTranslation(langPath: string): any {

    const xliffMap = {
      en: require('file-loader!../../assets/i18n/en.xlf').default,
      fr: require('file-loader!../../assets/i18n/fr.xlf').default,
    };

    /*if (isPlatformBrowser(this.platformId)) {
      if (this.transferState.hasKey(this.translocoStateKey)) {
        const transloco: any = this.transferState.get(this.translocoStateKey, null);
        this.transferState.remove(this.translocoStateKey);
        return of(transloco);
      }
    }*/

    return this.http.get(xliffMap[langPath], {responseType: 'text' as 'text'}).pipe(
      mergeMap(translation => {
        return fromPromise(xliff.xliff12ToJs(translation)).pipe(
          map(result => {
            const transloco: any = toTranslationFormat(result);
            return transloco;
          })
        );
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }
}

function toTranslationFormat(json) {
  const obj = json?.resources['ng2.template'];
  const translocoObject: any = Object.keys(obj).reduce((acc, key) => {
    acc[key] = obj[key].target;
    return acc;
  }, {});
  return translocoObject;
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['fr', 'en'],
        defaultLang: 'fr',
        fallbackLang: 'fr',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    },
    {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader}
  ]
})
export class TranslocoRootModule {
}
