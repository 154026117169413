import {Pipe, PipeTransform} from '@angular/core';
import {Link} from '../model/link';

@Pipe({
  name: 'socialLinksInfos'
})
export class SocialLinksInfosPipe implements PipeTransform {

  transform(link: Link): { icon: string, color: string } {
    let icon: string;
    let color: string;
    if (link.uri.indexOf('facebook') > -1) {
      icon = 'icon-facebook';
      color = '#3b5998';
    } else if (link.uri.indexOf('instagram') > -1) {
      icon = 'icon-instagram';
      color = '#e03469';
    } else if (link.uri.indexOf('pinterest') > -1) {
      icon = 'icon-pinterest';
      color = '#c30925';
    }
    return {icon, color};
  }

}
