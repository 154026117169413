import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { DrupalLinkDirective } from './drupal-link.directive';
import { DrupalLinkActiveDirective } from './drupal-link-active.directive';
import { DrupalLinkScrollToDirective } from './drupal-link-scroll-to.directive';
import { DrupalRichTextLinksDirective } from './drupal-rich-text-links.directive';


@NgModule({
  declarations: [DrupalLinkDirective, DrupalLinkActiveDirective, DrupalLinkScrollToDirective, DrupalRichTextLinksDirective],
  imports: [
    CommonModule
  ],
    exports: [
        DrupalLinkDirective,
        DrupalLinkActiveDirective,
        DrupalLinkScrollToDirective,
        DrupalRichTextLinksDirective
    ],
  providers: []
})
export class DrupalRouterModule {
}
