import {Inject, Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {
  getApiKey,
  getBillingAddress,
  getCurrency,
  getTotal,
  getUserMail,
  isStateCart,
  isStateCartOpen,
  isStateCheckout,
  onlyAllowGuests
} from './snipcart.selectors';
import {select, Store} from '@ngrx/store';
import * as fromSnipcart from './snipcart.reducer';
import {closeCart, openCart} from './snipcart.actions';
import {take} from 'rxjs/operators';
import {BillingAddress, Snipcart} from './snipcart.model';
import {addProduct, clearProducts, loadProducts} from './product/product.actions';
import {Item} from "../model/snipcart-state";
import {CartProduct} from "./product/product.reducer";
import {WINDOW} from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root'
})
export class SnipcartService {

  ready$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  cartOpen$: Observable<boolean> = this.snipcartStore.pipe(
    select(isStateCartOpen)
  );

  isCartState$: Observable<boolean> = this.snipcartStore.pipe(
    select(isStateCart)
  );

  isCheckOutState$: Observable<boolean> = this.snipcartStore.pipe(
    select(isStateCheckout)
  );

  apiKey$: Observable<string> = this.snipcartStore.pipe(
    select(getApiKey)
  );

  total$: Observable<number> = this.snipcartStore.pipe(
    select(getTotal)
  );

  onlyAllowGuests$: Observable<number> = this.snipcartStore.pipe(
    select(onlyAllowGuests)
  );


  billingAddress$: Observable<BillingAddress> = this.snipcartStore.pipe(select(getBillingAddress));

  userMail$: Observable<string> = this.snipcartStore.pipe(select(getUserMail));

  currency$: Observable<string> = this.snipcartStore.pipe(select(getCurrency));

  snipcart$: ReplaySubject<Snipcart> = new ReplaySubject<Snipcart>(1);
  snipcartStore$: ReplaySubject<any> = new ReplaySubject<any>(1);
  snipcartOrderConfirmed$: ReplaySubject<boolean> = new ReplaySubject<any>(1);

  #snipcart: Snipcart;
  set snipcart(value: Snipcart) {
    if (!this.#snipcart) {
      this.snipcart$.next(value);
      this.#snipcart = value;
      this.#snipcart.store.subscribe(() => {
        this.snipcartStore$.next(this.#snipcart.store.getState());
      });
      this.#snipcart.events.on('cart.confirmed', (cartConfirmResponse) => {
        this.snipcartStore.dispatch(clearProducts());
        this.snipcartOrderConfirmed$.next(true);
      });
    }
  }

  constructor(
    @Inject(WINDOW) protected readonly window,
    private snipcartStore: Store<fromSnipcart.State>
  ) {

  }

  checkHash() {
    if (location.hash.indexOf('#/cart') > -1) {
      this.openCart(false);
    } else if (location.hash === '') {
      this.closeCart();
    }
  }

  addProductsToCart(products: Item[]) {
    const cartProducts: CartProduct[] = products.map(product => {
      const cartProduct: CartProduct = {
        quantity: product.quantity,
        id: product.id,
        uuid: product.uniqueId,
        addedOn: product.addedOn,
      };
      return cartProduct;
    });
    this.snipcartStore.dispatch(loadProducts({products: cartProducts}));
  }

  addProductToCart(product: Item, openCartWhenAdded: boolean = false) {
    this.snipcartStore.dispatch(addProduct({product: JSON.parse(JSON.stringify(product))}));
    if (openCartWhenAdded) {
      this.openCart();
    }
  }

  toggleCart() {
    this.cartOpen$
      .pipe(take(1))
      .subscribe(cartIsOpen => {
        if (cartIsOpen) {
          this.closeCart();
        } else {
          this.openCart();
        }
      });
  }

  openCart(setHash: boolean = true) {
    this.snipcartStore.dispatch(openCart());
    if (setHash) {
      // location.hash = '/cart';
    }
  }

  closeCart() {
    this.snipcartStore.dispatch(closeCart());
    // location.hash = '';
  }

  refreshProducts(items: Item[]) {
    this.addProductsToCart(items);
  }


}
