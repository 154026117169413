import {Pipe, PipeTransform} from '@angular/core';
import {Resource, StoreResource} from 'ngrx-json-api';
import {Product, Variant} from '../model/product';
import {DrupalBody} from '../model/drupal-body';
import {ResourceToVariantPipe} from './resource-to-variant.pipe';
import {TitleToPathPipe} from './title-to-path.pipe';
import {ResourceToImagePipe} from './resource-to-image.pipe';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ShortenPipe, StripTagsPipe} from 'ngx-pipes';
import {ResourceToCategoryPipe} from './resource-to-category.pipe';
import {Category} from '../model/product-category';
import {AppConfigService} from '../config/app-config.service';
import {ResourcesToParagraphsPipe} from './resources-to-paragraphs.pipe';
import {ParagraphLocation} from '../model/paragraph-location';
import {ParagraphYoutube} from '../model/paragraph-youtube';
import {Image} from '../model/image';

@Pipe({
  name: 'resourceToProduct'
})
export class ResourceToProductPipe implements PipeTransform {

  constructor(
    private resourceToVariantPipe: ResourceToVariantPipe,
    private titleToPathPipe: TitleToPathPipe,
    private resourceToImagePipe: ResourceToImagePipe,
    private resourceToCategoryPipe: ResourceToCategoryPipe,
    private stripTagsPipe: StripTagsPipe,
    private shortenPipe: ShortenPipe,
    private config: AppConfigService,
    private resourcesToParagraphsPipe: ResourcesToParagraphsPipe
  ) {

  }

  transform(resource: Resource): Observable<Product> {

    if (resource) {

      let media$: Observable<Image[]>;
      if (Array.isArray(resource.relationships?.field_media?.reference)) {
        media$ = combineLatest(resource.relationships?.field_media?.reference?.map(mediaResource => {
          return this.resourceToImagePipe.transform(mediaResource?.relationships?.field_media_image?.data);
        })) as Observable<Image[]>;
      } else {
        media$ = of([]);
      }

      return combineLatest([
        this.resourceToImagePipe
          .transform(resource.relationships?.field_image?.reference?.relationships?.field_media_image?.data),
        media$
      ]).pipe(
        map(([image, media]: [Image, Image[]]) => {
          const {id, type} = resource;
          const title: string = resource.attributes?.title;
          const subTitle: string = resource.attributes?.field_subtitle;
          const body: DrupalBody = resource.attributes?.body;
          // const language: string = resource.attributes.langcode;
          const language = 'fr';
          let pathAlias: string = resource.attributes?.path?.alias;
          if (pathAlias?.indexOf(this.config.getConfig().account) === 1) {
            pathAlias = pathAlias.substring(this.config.getConfig().account.length + 1);
          }
          const bundleType = 'product';
          const basePath = '/' + bundleType + '/' + resource.attributes?.drupal_internal__product_id;
          const path: string = (pathAlias ? pathAlias : basePath);
          const ngPath: string = '/products/' + id;

          const descriptionPlainText: string = body?.summary ? body.summary : (body?.processed ? this.stripTagsPipe.transform(body.processed) : null);
          const shortDescription: string = descriptionPlainText ? this.shortenPipe
            .transform(descriptionPlainText, 100, '...', false) : null;
          const itemDescription: string = descriptionPlainText ? this.shortenPipe
            .transform(descriptionPlainText, 68, '...', false) : null;

          const metaDescription: string = descriptionPlainText ? this.shortenPipe
            .transform(descriptionPlainText, 160, '...', false) : null;

          const variants: Variant[] = resource.relationships?.variations?.reference
            .filter(variantResource => variantResource.attributes.status)
            .map(variantResource => {
              return this.resourceToVariantPipe.transform(variantResource, title, image, metaDescription);
            });

          const firstVariant: Variant = variants?.length > 0 ? variants[0] : null;

          let categories: Category[];
          if (resource.relationships?.field_sections?.reference) {

            categories = resource.relationships?.field_sections?.reference
              .filter(categoryResource => !!categoryResource)
              .map(categoryResource => {
                return this.resourceToCategoryPipe.transform(categoryResource);
              });

          }
          // TODO not optimal but needs it to ease the merge of the data in the store,
          // we don't want a [] to erase an existing array with data

          if (categories.length === 0) {
            categories = null;
          }

          const paragraphs: ParagraphLocation[] | ParagraphYoutube[] | StoreResource[] = this.resourcesToParagraphsPipe
            .transform(resource?.relationships?.field_paragraphs?.reference?.filter((item) => !!item));

          const fragment: string = this.titleToPathPipe.transform(resource.attributes?.title);

          const unavailable: boolean = resource.attributes?.field_unavailable;

          const product: Product = {
            id,
            type,
            title,
            subTitle,
            body,
            image,
            media,
            variants,
            firstVariant,
            path,
            ngPath,
            fragment,
            shortDescription,
            language,
            metaDescription,
            description: descriptionPlainText,
            itemDescription,
            categories,
            unavailable,
            paragraphs
          };

          return product;
        })
      );

    }

    return of(null);

  }

}
