import {distinctUntilChanged} from 'rxjs/operators';
import {differenceWith as _differenceWith, isEqual as _isEqual} from 'lodash';

export function loadashArrayDifferenceWith() {

  return distinctUntilChanged((oldValue: any[], newValue: any[]) => {
    const differences: any[] = _differenceWith(oldValue, newValue, _isEqual);
    return differences.length === 0 && oldValue?.length === newValue?.length;
  });

}
