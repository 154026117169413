import {Pipe, PipeTransform} from '@angular/core';
import {NgrxJsonApiService, Resource} from 'ngrx-json-api';
import {Observable} from 'rxjs';
import {map, mergeMap, switchMap} from 'rxjs/operators';

@Pipe({
  name: 'getProductImage'
})
export class GetProductImagePipe implements PipeTransform {

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService
  ) {

  }

  transform(product: Resource, zone: string, profile: string): Observable<string> {
    return this.ngrxJsonApiService.getZone(zone)
      .selectStoreResource(product.relationships.field_image.data)
      .pipe(
        switchMap(imageMedia => {
          return this.ngrxJsonApiService.getZone(zone)
            .selectStoreResource(imageMedia.relationships.field_media_image.data)
            .pipe(
              map(mediaImage => {
                return mediaImage.links[profile ? profile : 'medium'].href;
              })
            );
        })
      );
  }

}
