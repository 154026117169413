import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ActivatedRouteSnapshot, Params} from '@angular/router';
import {RouterReducerState} from '@ngrx/router-store';
import {ResourceTypes} from "../../ngrx-json-api/ngrx-json-api-definitions";

export interface RouteData {
  contentType: string;
  context?: string;
}

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

export const selectRouteNestedParams = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});

export const selectRouteNestedData = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  let data: any = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    data = {
      ...data,
      ...currentRoute.data,
    };
  }
  return data;
});

export const selectRouteNestedFragment = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  let fragment: string = null;
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    fragment = currentRoute.fragment;
  }
  return fragment;
});

export const selectRouteCategoryOutletParams = createSelector(selectRouter, (router) => {
  const currentRoute = router?.state?.root;
  let params: Params = {};

  const flatten = (obj: ActivatedRouteSnapshot[]) => {
    const array = Array.isArray(obj) ? obj : [obj];
    return array.reduce((acc, value) => {
      const cloned = JSON.parse(JSON.stringify(value));
      acc.push(cloned);
      if (cloned.children) {
        acc = acc.concat(flatten(cloned.children));
        delete cloned.children;
      }
      return acc;
    }, []);
  };

  if (currentRoute?.children) {
    const categoryOutlet = flatten(currentRoute.children).filter(child => child.outlet === 'category').shift();
    if (categoryOutlet) {
      params = categoryOutlet.params;
    }
  }

  return params;
});

export const selectRouteContentId = createSelector(
  selectRouteNestedParams,
  (params: Params) => {
    return params?.contentId;
  }
);

export const selectRouteCategoryId = createSelector(
  selectRouteNestedData,
  selectRouteNestedParams,
  selectRouteNestedFragment,
  (data: RouteData, params: Params, fragment: string) => {
    let categoryId: string;
    if (data.contentType === ResourceTypes.storeSection && params?.contentId) {
      categoryId = params?.contentId;
    } else if (fragment?.indexOf('section--') > -1) {
      categoryId = fragment.split('section--').pop();
    }
    return categoryId;
  }
);

export const selectRouteHomeFragment = createSelector(
  selectRouteNestedFragment,
  (fragment: string) => {
    const isHomeFragment: boolean = fragment === 'appWelcome';
    return isHomeFragment;
  }
);

export const selectRouteContentType = createSelector(
  selectRouteNestedData,
  (data: RouteData) => {
    return data?.contentType;
  }
);

export const selectRouteResourceIdentifier = createSelector(
  selectRouteContentType,
  selectRouteContentId,
  (contentType: string, contentId: string) => {
    if (contentType && contentId) {
      return {type: contentType, id: contentId};
    }
    return null;
  }
);
