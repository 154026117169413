import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as AlertsActions from './alerts.actions';
import {setAlerts} from './alerts.actions';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {ApiGetSuccessAction, NgrxJsonApiActionTypes, Query} from 'ngrx-json-api';
import {NgrxJsonApiQueries} from '../../ngrx-json-api/ngrx-json-queries';
import {Action} from '@ngrx/store';
import {throwError} from 'rxjs';
import {ResourceToAlertPipe} from '../../pipes/resource-to-alert.pipe';
import {Resource} from '@madeinlune/ngrx-json-api/src/interfaces';
import {Alert} from './alert.models';
import {LOCAL_STORAGE} from '@ng-web-apis/common';


@Injectable()
export class AlertsEffects {


  getAlertsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NgrxJsonApiActionTypes.API_GET_SUCCESS),
        filter((action: ApiGetSuccessAction) => {
          const query: Query = action.payload.query;
          return query.queryId === NgrxJsonApiQueries.messagesQuery.queryId;
        }),
        switchMap((action: ApiGetSuccessAction) => {

            const rawAlertsPrefs: string = this.localStorage ? this.localStorage.getItem('alerts') : '';
            let alertsPrefs: any = null;
            if (rawAlertsPrefs) {
              alertsPrefs = JSON.parse(rawAlertsPrefs);
            }

            const actions: Action[] = [];
            const query: Query = action.payload.query;
            const data: Resource[] = action.payload.jsonApiData.data;
            const meta: { count: number | string } = action.payload.jsonApiData.meta;
            const links: { last: string, next: string, self: string } = action.payload.jsonApiData.links;
            if (meta?.count) {
              meta.count = parseInt(meta.count as string, 10);
            }

            const alerts: Alert[] = data.filter(alertResource => !!alertResource)
              .map(alertResource => this.resourceToAlertPipe.transform(alertResource));

            let alertsDef: any[];
            if (alertsPrefs) {
              alertsDef = alerts.filter(alert => {
                return alertsPrefs[alert.id] !== true;
              });
            } else {
              alertsDef = alerts;
            }

            const finalAlterts: Alert[] = alertsDef?.length > 0 ? [alertsDef[0]] : [];

            actions.push(setAlerts({alerts: finalAlterts}));

            return actions;

          }
        ),
        catchError(error => throwError(error))
      );
    }
  );

  removeAlert$ = isPlatformBrowser(PLATFORM_ID) ? createEffect(() => {
    return this.actions$.pipe(
      ofType(AlertsActions.removeAlert),
      tap(action => {
        if (action.alert.forgettable) {
          if (this.localStorage) {
            let alerts: any;
            if (this.localStorage.getItem('alerts')) {
              alerts = JSON.parse(this.localStorage.getItem('alerts'));
            } else {
              alerts = {};
            }
            alerts = {
              ...alerts,
              [action.alert.id]: true
            };
            this.localStorage.setItem('alerts', JSON.stringify(alerts));
          }
        }
      })
    );
  }, {dispatch: false}) : null;


  constructor(
    private actions$: Actions,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private resourceToAlertPipe: ResourceToAlertPipe
  ) {
  }

}
