import {Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, TemplateRef, Type} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Observable, ReplaySubject} from 'rxjs';
import {LuneWindowData, WindowPassport} from '../window.model';
import {filter, mergeMap} from 'rxjs/operators';
import {TemplatesService} from '../../services/templates.service';
import {Store} from '@ngrx/store';
import {DrupalRouterService} from '../../drupal-router/drupal-router.service';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit, OnDestroy {

  passport$: ReplaySubject<WindowPassport> = new ReplaySubject<WindowPassport>(1);

  contentTemplate$: Observable<TemplateRef<any>>;

  contentComponent$: Observable<Type<any>>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: LuneWindowData,
    private templatesService: TemplatesService,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    private store: Store<any>,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private drupalService: DrupalRouterService
  ) {
  }

  ngOnDestroy(): void {

    if (this.data.passport.closePath) {
      this.drupalService.go(this.data.passport.closePath);
    }

  }

  ngOnInit(): void {

    if (this.elementRef?.nativeElement?.parentElement?.parentElement) {
      this.renderer.addClass(this.elementRef.nativeElement.parentElement.parentElement, this.data.overlayClass);
    }

    this.passport$.next(this.data.passport);

    this.contentTemplate$ = this.passport$.pipe(
      filter(passport => !!passport),
      filter(passport => !!passport.components.content.template),
      mergeMap(passport => {
        return this.templatesService.getTemplate$(passport.components.content.template);
      })
    );

    this.contentComponent$ = this.passport$.pipe(
      filter(passport => !!passport),
      filter(passport => !!passport.components.content.component),
      mergeMap(passport => {
        return this.templatesService.getComponent$(passport.components.content.component);
      })
    );

  }

  close() {
    this.bottomSheetRef.dismiss();
  }

}
