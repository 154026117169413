import {Pipe, PipeTransform} from '@angular/core';
import {Location, ParagraphLocation} from '../model/paragraph-location';
import {Resource} from 'ngrx-json-api';
import {Address} from '../model/address';

@Pipe({
  name: 'resourceToParagraphLocation'
})
export class ResourceToParagraphLocationPipe implements PipeTransform {

  transform(resource: Resource): ParagraphLocation {

    if (resource) {
      const {id, type} = resource;
      const address: Address = resource.attributes.field_address;
      const location: Location = resource.attributes.field_location;
      const phone: string = resource.attributes.field_telephone;
      const mail: string = resource.attributes.field_email;
      const paragraphLocation: ParagraphLocation = {id, type, address, location, phone, mail};
      return paragraphLocation;
    }

    return null;
  }

}
