import {createAction, props} from '@ngrx/store';
import {NavigationExtras} from '@angular/router';
import {ResourceIdentifier} from "@madeinlune/ngrx-json-api/src/interfaces";

export const go = createAction(
  '[Router] Go',
  props<{ payload: { url: string }}>()
);

export const back = createAction(
  '[Router] Back'
);

export const forward = createAction(
  '[Router] Forward'
);

export const updateMeta = createAction(
  '[Router] UpdateMeta',
  props<{ content: ResourceIdentifier}>()
);
