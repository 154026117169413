import {createAction, props} from '@ngrx/store';

export const initializeAnalytics = createAction(
  '[Analytics] Initialize Analytics',
  props<{ accountId: string }>()
);

export const initializeGTM = createAction(
  '[Analytics] Initialize GTM',
  props<{ containerId: string }>()
);

export const startTracking = createAction(
  '[Analytics] Start Tracking Analytics'
);

export const stopTracking = createAction(
  '[Analytics] Stop Tracking Analytics'
);

