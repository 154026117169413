import {createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as CatalogProductActions from './catalog-product.actions';
import {Product} from '../../model/product';
import {selectCategoryProductIds} from '../catalog-category/catalog-category.reducer';
import {ResourceIdentifier} from '@madeinlune/ngrx-json-api/src/interfaces';

export interface ProductsGroup {

  products?: ResourceIdentifier[];
  title: string;
  breadcrumbTitle?: string;
  path: string;
  fragment?: string;

}

export const catalogProductsFeatureKey = 'catalogProducts';

export interface State extends EntityState<Product> {
  // additional entities state properties
  productsGroup: ProductsGroup;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  productsGroup: null
});


export const reducer = createReducer(
  initialState,
  on(CatalogProductActions.addCatalogProduct,
    (state, action) => adapter.addOne(action.product, state)
  ),
  on(CatalogProductActions.upsertCatalogProduct,
    (state, action) => adapter.upsertOne(action.product, state)
  ),
  on(CatalogProductActions.addCatalogProducts,
    (state, action) => adapter.addMany(action.products, state)
  ),
  on(CatalogProductActions.upsertCatalogProducts,
    (state, action) => adapter.upsertMany(action.products, state)
  ),
  on(CatalogProductActions.updateCatalogProduct,
    (state, action) => adapter.updateOne(action.product, state)
  ),
  on(CatalogProductActions.updateCatalogProducts,
    (state, action) => adapter.updateMany(action.products, state)
  ),
  on(CatalogProductActions.deleteCatalogProduct,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(CatalogProductActions.deleteCatalogProducts,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(CatalogProductActions.loadCatalogProducts,
    (state, action) => adapter.setAll(action.products, state)
  ),
  on(CatalogProductActions.clearCatalogProducts,
    state => adapter.removeAll(state)
  ),
  on(CatalogProductActions.setProductsGroup, (state, {productsGroup}) => {
      return {...state, productsGroup};
    }
  ),
  on(CatalogProductActions.clearProductsGroup, (state) => {
      return {...state, productsGroup: null};
    }
  )
);

export const selectCatalogProductState = createFeatureSelector<State>(catalogProductsFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


export const selectAllProducts = createSelector(
  selectCatalogProductState,
  selectAll
);

export const selectProductsEntities = createSelector(
  selectCatalogProductState,
  selectEntities
);

export const selectProductById = (productId: string) => createSelector(
  selectProductsEntities,
  (productEntities) => !!productEntities && productEntities[productId]
);

export const selectProductCategoryRefs = (productId: string) => createSelector(
  selectProductById(productId),
  (product) => {
    return product?.categories;
  }
);

export const selectProductsByCategoryId = (categoryId: string) => createSelector(
  selectCategoryProductIds(categoryId),
  selectProductsEntities,
  (productRefs, productEntities) => productRefs ? productRefs
    .map(productRef => productEntities[productRef.id]) : []
);

export const selectProductsGroup = createSelector(
  selectCatalogProductState,
  (state) => state.productsGroup
);
