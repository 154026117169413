import {Pipe, PipeTransform} from '@angular/core';
import {Category} from '../model/product-category';
import {CategoryMenu, CategoryMenuItem} from '../model/category-menu';

@Pipe({
  name: 'categoriesMenuBuilder'
})
export class CategoriesMenuBuilderPipe implements PipeTransform {

  transform(categories: Category[]): CategoryMenu {

    const map: { [categoryId: string]: CategoryMenuItem } = {};
    const categoryMenu: CategoryMenu = {
      children: this.getCategoryChildren(null, categories, 0, [], map),
      map
    };
    return categoryMenu;
  }

  getCategoryChildren(parent: Category, categories: Category[], depth: number, breadcrumbs: CategoryMenuItem[], map): CategoryMenuItem[] {
    return categories
      .filter(category => {
        if (!parent) {
          return !category.parent;
        } else if (category.parent) {
          return category.parent.id === parent.id;
        }
      })
      .map(category => {
        return this.createCategoryMenuItem(category, categories, depth, breadcrumbs, map);
      });
  }

  createCategoryMenuItem(
    category: Category,
    categories: Category[],
    depth: number,
    breadcrumbs: CategoryMenuItem[],
    map: { [categoryId: string]: CategoryMenuItem }
  ): CategoryMenuItem {
    const categoryMenuItem: CategoryMenuItem = {
      label: category.title,
      path: category.path,
      fragment: category.fragment,
      source: category,
      breadcrumbs: [...breadcrumbs],
      depth
    };
    map[category.id] = categoryMenuItem;
    categoryMenuItem.breadcrumbs = [...breadcrumbs, {...categoryMenuItem}];
    categoryMenuItem.children = this.getCategoryChildren(category, categories, depth + 1, categoryMenuItem.breadcrumbs, map);
    return categoryMenuItem;
  }

}
