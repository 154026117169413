import {Pipe, PipeTransform} from '@angular/core';
import {Resource, ResourceIdentifier} from 'ngrx-json-api';
import {Category} from '../model/product-category';
import {DrupalBody} from '../model/drupal-body';
import {TitleToPathPipe} from './title-to-path.pipe';
import {ShortenPipe, StripTagsPipe} from 'ngx-pipes';
import {AppConfigService} from '../config/app-config.service';

@Pipe({
  name: 'resourceToCategory'
})
export class ResourceToCategoryPipe implements PipeTransform {

  constructor(
    private titleToPathPipe: TitleToPathPipe,
    private stripTagsPipe: StripTagsPipe,
    private shortenPipe: ShortenPipe,
    private config: AppConfigService,
  ) {
  }

  transform(resource: Resource): Category {

    if (resource) {
      const {id, type} = resource;
      const title: string = resource.attributes?.title;
      const body: DrupalBody = resource.attributes?.body;
      // const language: string = resource.attributes.langcode;
      const language = 'fr';
      let pathAlias: string = resource.attributes?.path?.alias;
      if (pathAlias?.indexOf(this.config.getConfig().account) === 1) {
        pathAlias = pathAlias.substring(this.config.getConfig().account.length + 1);
      }
      const basePath = '/node/' + resource.attributes?.drupal_internal__nid;
      const path: string = (pathAlias ? pathAlias : basePath);
      const fragment: string = 'section--' + resource.id;

      const ngPath: string = '/categories/' + id;

      const descriptionPlainText: string = body?.processed ? this.stripTagsPipe.transform(body.processed) : null;
      const metaDescription: string = descriptionPlainText ? this.shortenPipe
        .transform(descriptionPlainText, 160, '...', false) : null;

      const parent: ResourceIdentifier = resource.relationships?.field_parent.data;
      const weight: number = resource.attributes?.field_weight;

      const unavailable: boolean = resource.attributes?.field_unavailable;

      const products: ResourceIdentifier[] = resource.relationships?.category_product_list?.data;

      const category: Category = {
        id,
        type,
        title,
        body,
        path,
        language,
        metaDescription,
        fragment,
        parent,
        weight,
        unavailable,
        ngPath,
        products
      };

      return category;
    }

    return null;

  }

}
