import {Pipe, PipeTransform} from '@angular/core';
import {Image, ImageProfile} from "../model/image";

@Pipe({
  name: 'getProfile'
})
export class GetProfilePipe implements PipeTransform {

  transform(image: Image, profile: string): ImageProfile {
    if (image?.profiles[profile]) {
      return image?.profiles[profile];
    }
    return null;

  }

}
