import {Injectable, Optional, TemplateRef, Type} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, map, shareReplay, take, tap} from 'rxjs/operators';
import {CoreComponentsConfig} from '../core-components-config';


@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  templates: { [templateId: string]: TemplateRef<any> } = {};

  templates$: ReplaySubject<{ [templateId: string]: TemplateRef<any> }> = new ReplaySubject<{ [templateId: string]: TemplateRef<any> }>();

  components: { [componentId: string]: Type<any> } = {};

  components$: ReplaySubject<{ [componentId: string]: Type<any> }> = new ReplaySubject<{ [componentId: string]: Type<any> }>(1);

  public test: any;

  public loadingComponents: string[] = [];

  private config: CoreComponentsConfig;

  constructor(@Optional() config?: CoreComponentsConfig) {

    this.config = config;
    this.components$.next(this.components);

  }

  addTemplate(templateId: string, template: TemplateRef<any>) {
    this.templates[templateId] = template;
    this.templates$.next(this.templates);
  }

  addComponent(componentId: string, component: Type<any>) {
    this.components[componentId] = component;
    this.components$.next(this.components);
  }

  getTemplate$(templateId: string): Observable<TemplateRef<any>> {
    return this.templates$.pipe(
      filter(templates => !!templates[templateId]),
      map(templates => {
        return templates[templateId];
      }));
  }

  getComponent$(componentId: string): Observable<Type<any>> {
    return this.components$.pipe(
      tap(components => {
        if (
          this.config &&
          !components[componentId] &&
          this.loadingComponents.indexOf(componentId) === -1 &&
          this.config.componentCreationFunctions[componentId]
        ) {
          this.loadingComponents.push(componentId);
          this.config.componentCreationFunctions[componentId].apply(this).then(
            (result) => {
              this.addComponent(componentId, result);
            },
            (error) => {
              console.error('error', error);
            }
          );
        }
      }),
      map(components => {
        return components[componentId];
      }),
      filter(component => !!component),
      take(1),
      shareReplay(1)
    );
  }

}
