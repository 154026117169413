import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {filter, map, mergeMap, take, tap} from 'rxjs/operators';

import * as WindowActions from './window.actions';
import {addWindowId, removeWindowId} from './window.actions';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WindowComponent} from './window/window.component';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {BottomSheetComponent} from './bottom-sheet/bottom-sheet.component';

import * as fromWindow from './window.reducer';
import {select, Store} from '@ngrx/store';
import {isWindowOpened} from './window.selectors';
import {BottomSheetOptions} from './window.model';
import {TemplatesService} from '../services/templates.service';


@Injectable()
export class WindowEffects {


  openWindows$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WindowActions.openWindow),
      /** An EMPTY observable only emits completion. Replace with your own observable API request */
      tap((action) => {
        if (action?.options?.passport?.windowComponent) {
          this.templatesService.getComponent$(action?.options?.passport?.windowComponent)
            .pipe(
              filter(windowComponent => !!windowComponent),
              take(1)
            ).subscribe(windowComponent => {
            this.modalService.open(windowComponent, {
              ...action?.options?.dialogConfig,
              data: {
                ...action?.options?.dialogConfig?.data,
                passport: action?.options?.passport
              }
            });

          });
        } else {

          const windowRef: MatDialogRef<any> = this.modalService.open(WindowComponent, {
            ...action?.options?.dialogConfig,
            data: {
              ...action?.options?.dialogConfig?.data,
              passport: action?.options?.passport
            }
          });

          windowRef.afterClosed().subscribe(() => {

            // console.log('action?.options?.routeWhenClosed', action?.options?.routeWhenClosed);
            if (action?.options?.routeWhenClosed) {
              // this.router.navigateByUrl(action?.options?.routeWhenClosed);
            } else if (action?.closePath) {
              // this.router.navigateByUrl(action?.closePath);
            }

          });

        }

      })
    );
  }, {dispatch: false});

  openBottomSheet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WindowActions.openBottomSheet),
      mergeMap((action) => {
        return this.windowStore
          .pipe(
            select(isWindowOpened(action.options.windowId)),
            map(isOpened => {
              return {isOpened, options: action.options};
            }),
            take(1)
          );
      }),
      filter((windowActionInfos: { isOpened: boolean, options: BottomSheetOptions }) => !windowActionInfos.isOpened),
      tap((windowActionInfos: { isOpened: boolean, options: BottomSheetOptions }) => {
          // console.log('isOpened', windowActionInfos.isOpened);
          const options: BottomSheetOptions = windowActionInfos.options;
          // console.log('options', windowActionInfos.options);
          const bottomSheetRef: MatBottomSheetRef = this.bottomSheetService.open(BottomSheetComponent, {
            ...options.bottomSheetConfig,
            panelClass: [
              'mat-typography',
              ...options.bottomSheetConfig.panelClass
            ],
            data: {
              ...options.bottomSheetConfig?.data,
              passport: options.passport,
              queryParamId: options.queryParamId,
              windowId: options.windowId,
              overlayClass: 'shop-utils-theme'
            }
          });
          bottomSheetRef.afterDismissed().subscribe(() => {
            this.windowStore.dispatch(removeWindowId({windowId: options.windowId}));
          });
          this.windowStore.dispatch(addWindowId({windowId: options.windowId}));
        }
      )
    )
      ;
  }, {dispatch: false});


  constructor(
    private actions$: Actions,
    private modalService: MatDialog,
    private bottomSheetService: MatBottomSheet,
    private windowStore: Store<fromWindow.State>,
    private templatesService: TemplatesService
  ) {
  }

}
