import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Observable, of, ReplaySubject, throwError} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {WINDOW} from '@ng-web-apis/common';
import {HttpClient} from '@angular/common/http';
import {catchError, filter, map, take} from 'rxjs/operators';
import {TransferStateService} from '@scullyio/ng-lib';

export class AppConfig {
  backend: string;
  account: string;
  devMode: boolean;
}

export const APP_CONFIG_LOADER = new InjectionToken<AppConfigLoader>('AppConfigLoader');

export interface AppConfigLoader {
  loadConfig(appConfig: AppConfig | undefined): Observable<AppConfig>;
}

export abstract class AppConfigLoaderBase {

  appConfig;

  constructor(protected window, protected document) {
  }

  protected getConfigKey() {
    if (this.localOverride('app_config_key')) {
      return this.localOverride('app_config_key');
    }
    return this.appConfig.defaultConfig;
  }

  protected getResolvedConfig(hostname) {
    if (this.appConfig) {
      const appConfigKey = this.getConfigKey();
      const configObject = {
        ...this.appConfig.common,
        ...this.appConfig.configs[appConfigKey]
      };
      return {
        backend: configObject.backendUrl,
        accountId: configObject.accountId,
        devMode: configObject.devMode,
      };
    } else {
      throw Error('no appConfig');
    }
  }

  protected getBackendUrl(hostname) {
    if (this.localOverride('backend_url')) {
      return this.localOverride('backend_url');
    }
    const environmentConfigObject = this.getResolvedConfig(hostname);
    if (environmentConfigObject) {
      return environmentConfigObject['backend'];
    } else {
      throw new Error('no backend url found (hostname: ' + hostname + ')');
    }
  }

  protected getStoreAccountId(hostname) {
    if (this.localOverride('store_account')) {
      return this.localOverride('store_account');
    }
    const config = this.getResolvedConfig(hostname);
    if (config) {
      return config['accountId'];
    }
    return false;
  }

  protected getDevMode(hostname) {
    if (this.localOverride('dev_mode') !== null) {
      try {
        return JSON.parse(this.localOverride('dev_mode'));
      } catch (e) {
      }
    }
    const environmentConfigObject = this.getResolvedConfig(hostname);
    if (environmentConfigObject) {
      return environmentConfigObject['devMode'];
    } else {
      throw new Error('no dev mode found (hostname: ' + hostname + ')');
    }
  }

  protected getHostRegex(hostExpr) {
    if (hostExpr instanceof RegExp) {
      return hostExpr;
    } else if (typeof hostExpr === 'string') {
      if (hostExpr.length > 2 && hostExpr.charAt(0) === '/' && hostExpr.charAt(hostExpr.length - 1) === '/') {
        return new RegExp(hostExpr.substr(1, hostExpr.length - 2));
      }
      return new RegExp('^' + hostExpr + '$');
    }
  }

  protected getHostMapMatch(hostMap, hostname) {
    try {
      if (hostMap) {
        const candidates = hostMap.filter((hostMapping: any) => {
          return this.getHostRegex(hostMapping.host).test(hostname);
        });
        if (candidates.length > 0) {
          return candidates.shift();
        }
      }
    } catch (e) {
      console.error('hostname matching failed', e);
    }
    return false;
  }

  protected abstract localOverride(key);
}

@Injectable()
export class BrowserAppConfigLoader extends AppConfigLoaderBase implements AppConfigLoader {

  dataConfig;
  dataAccount;
  dataBackend;

  constructor(
    @Inject(WINDOW) protected window,
    @Inject(DOCUMENT) protected document,
    protected httpClient: HttpClient
  ) {
    super(window, document);
  }

  protected getConfigKey(): any {
    return super.getConfigKey();
  }

  loadConfig(appConfig: AppConfig | undefined): Observable<AppConfig> {

    if (appConfig) {

      if (appConfig) {
        this.appConfig = appConfig;
      }
      return of({
        backend: this.localOverride('backendUrl') ?? appConfig.backend,
        devMode: this.localOverride('devMode') ?? appConfig.devMode,
        account: this.localOverride('accountId') ?? appConfig.account,
      });

    } else {

      return this.httpClient.get('config/appConfig.json').pipe(
        map((config: any): AppConfig => {
          if (config) {
            this.appConfig = config;
          }
          return {
            backend: this.localOverride('backendUrl') ?? this.appConfig.backendUrl,
            devMode: this.localOverride('devMode') ?? this.appConfig.devMode,
            account: this.localOverride('accountId') ?? this.appConfig.accountId,
          };
        }),
        catchError((error) => throwError(error))
      );

    }

  }

  protected getBackendUrl(hostname) {
    if (this.dataBackend) {
      return this.dataBackend;
    }
    if (this.dataConfig && this.dataConfig.backend) {
      return this.dataConfig.backend;
    }
    return super.getBackendUrl(hostname);
  }

  protected getStoreAccountId(hostname) {
    if (this.dataAccount) {
      return of(this.dataAccount);
    } else if (this.dataConfig && this.dataConfig.accountId) {
      return of(this.dataConfig.accountId);
    }
    const configAccountId = super.getStoreAccountId(hostname);
    if (configAccountId) {
      return of(configAccountId);
    }
    const hostMap = (this.appConfig && this.appConfig.accountHostMap);
    const hostnameMatch = hostMap && this.getHostMapMatch(hostMap, hostname);
    if (hostnameMatch && hostnameMatch.accountId) {
      return of(hostnameMatch.accountId);
    }
    return this.lookupAccountByHostname(this.document.location.hostname);
  }

  protected getDevMode(hostname): any {
    if (this.dataConfig && typeof this.dataConfig.devMode === 'boolean') {
      return this.dataConfig.devMode;
    }
    return super.getDevMode(hostname);
  }


  protected localOverride(key) {
    if (new URL(window.location.href).searchParams.get('_' + key)) {
      return new URL(window.location.href).searchParams.get('_' + key);
    }
    if (localStorage && localStorage.getItem('_' + key)) {
      return JSON.parse(localStorage.getItem('_' + key));
    }
    return null;
  }

  protected lookupAccountByHostname(hostname) {
    return this.httpClient.get(this.getBackendUrl(hostname) + '/jsonrpc', {
      params: {
        query: JSON.stringify({
          jsonrpc: '2.0',
          method: 'quickshop.lookup_account',
          params: {store_domain: hostname},
          id: 'lookup',
        })
      }
    }).pipe(
      map((response: any) => {
        return response.result.account_id;
      })
    );
  }

}

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: AppConfig;

  public appConfig$: ReplaySubject<AppConfig> = new ReplaySubject<AppConfig>(1);

  constructor(
    @Inject(APP_CONFIG_LOADER) protected appConfigLoader: AppConfigLoader,
    private transferStateService: TransferStateService
  ) {
    this.appConfig$.next(null);
  }

  loadAppConfig(appConfig: AppConfig | undefined): Observable<AppConfig> {
    return this.appConfigLoader.loadConfig(appConfig).pipe(
      filter(config => !!config),
      take(1),
      map(config => {
        this.appConfig = config;
        this.appConfig$.next(this.appConfig);
        return this.appConfig;
      })
    );
  }

  getConfig(): AppConfig {
    return this.appConfig;
  }

}
