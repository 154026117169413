import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';
import {Alert, AlertLevel, AlertOption, AlertOptions} from '../components/alerts/alert.models';

@Pipe({
  name: 'resourceToAlert'
})
export class ResourceToAlertPipe implements PipeTransform {

  constructor() {
  }

  transform(resource: Resource): Alert {

    if (resource) {
      const {id, type} = resource;
      const title = '';
      const description: string = resource.attributes?.body?.value ? resource.attributes.body.value : '';
      // const language: string = resource.attributes.langcode;
      const language = 'fr';
      const options: AlertOption[] = resource.attributes?.field_message_options ? resource.attributes.field_message_options : [];
      const level: AlertLevel = resource.attributes?.field_message_type;

      const closeable: boolean = options.indexOf(AlertOptions.closeable) > -1;
      const sticky: boolean = options.indexOf(AlertOptions.sticky) > -1;
      const forgettable: boolean = options.indexOf(AlertOptions.forgettable) > -1;

      const alert: Alert = {
        id,
        type,
        description,
        title,
        options,
        level,
        closeable,
        sticky,
        forgettable,
        weight: 0
      };
      return alert;
    }

    return null;

  }

}
