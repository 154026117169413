import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {CartProduct} from "./product.reducer";

export const loadProducts = createAction(
  '[Product/API] Load Products',
  props<{ products: CartProduct[] }>()
);

export const addProduct = createAction(
  '[Product/API] Add Product',
  props<{ product: CartProduct }>()
);

export const upsertProduct = createAction(
  '[Product/API] Upsert Product',
  props<{ product: CartProduct }>()
);

export const addProducts = createAction(
  '[Product/API] Add Products',
  props<{ products: CartProduct[] }>()
);

export const upsertProducts = createAction(
  '[Product/API] Upsert Products',
  props<{ products: CartProduct[] }>()
);

export const updateProduct = createAction(
  '[Product/API] Update Product',
  props<{ product: Update<CartProduct> }>()
);

export const updateProducts = createAction(
  '[Product/API] Update Products',
  props<{ products: Update<CartProduct>[] }>()
);

export const deleteProduct = createAction(
  '[Product/API] Delete Product',
  props<{ id: string }>()
);

export const deleteProducts = createAction(
  '[Product/API] Delete Products',
  props<{ ids: string[] }>()
);

export const clearProducts = createAction(
  '[Product/API] Clear Products'
);
