import {Injectable, Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'youTubeVideoId'
})
@Injectable()
export class YouTubeVideoIdPipe implements PipeTransform {

  constructor() {
  }


  transform(url: string): string {
    let videoId: string;
    if (url.indexOf('http') > -1) {
      videoId = url.split('?v=')[1];
    } else {
      videoId = url;
    }
    return videoId;
  }

}
