// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {combineLatest, EMPTY, Observable, of} from 'rxjs';

import {select, Store} from '@ngrx/store';
import {Product} from '../model/product';
import {PathInfos} from '../model/path-infos';
import {filter, map, switchMap} from 'rxjs/operators';
import {ResourceTypes} from '../ngrx-json-api/ngrx-json-api-definitions';
import {StorePage} from '../model/store-page';
import {Category} from '../model/product-category';
import {ResourceIdentifier} from '@madeinlune/ngrx-json-api/src/interfaces';
import {getPageById} from '../store/luneshop/selectors/lune-shop.selectors';
import {
  selectRouteCategoryId,
  selectRouteHomeFragment,
  selectRouteResourceIdentifier
} from '../store/router/router.selectors';
import {selectCategoryById} from '../store/catalog-category/catalog-category.reducer';
import {loadashObjectIsEqual} from '../rxjs-operators';
import {IS_XS} from './ui.providers';
import {selectProductById} from '../store/catalog-product/catalog-product.reducer';
import {isScullyRunning} from '@scullyio/ng-lib';
import {LOCAL_STORAGE} from '@ng-web-apis/common';

export const IS_HOME = new InjectionToken<Observable<boolean>>(
  'A stream with routed Section'
);

export const CURRENT_ROUTED_CONTENT = new InjectionToken<Observable<ResourceIdentifier>>(
  'A stream with routed Content'
);

export const CURRENT_ROUTED_PAGE = new InjectionToken<Observable<StorePage>>(
  'A stream with routed Page'
);

export const CURRENT_ROUTED_PRODUCT = new InjectionToken<Observable<Product>>(
  'A stream with routed Product'
);

export const CURRENT_ROUTED_PRODUCT_ID = new InjectionToken<Observable<string>>(
  'CURRENT_ROUTED_PRODUCT_ID'
);

export const CURRENT_ROUTED_CATEGORY = new InjectionToken<Observable<Category>>(
  'A stream with routed Category'
);

export const DEFAULT_PRODUCT_CATEGORY = new InjectionToken<Observable<string>>(
  'A stream with the default product category'
);

export const INITIAL_URL = new InjectionToken<Observable<string>>(
  'A stream with the initial URL'
);

export const LAST_NAVIGATION = new InjectionToken<Observable<any>>(
  'A stream with the last Navigation'
);

export const IS_CATALOG_CONTEXT = new InjectionToken<Observable<boolean>>(
  'A stream to the if the current navigation is in the Catalog Context (Home or Category)'
);

export const IS_PRODUCT_CONTEXT = new InjectionToken<Observable<boolean>>(
  'A stream to the if the current navigation is Product'
);

export const IS_INFORMATIONS_CONTEXT = new InjectionToken<Observable<boolean>>(
  'A stream to the if the current navigation is Informations'
);

export const GET_PATH_INFOS = new InjectionToken<Observable<PathInfos>>(
  'A stream with the current pathInfos'
);

export const ROUTE_LANGUAGE = new InjectionToken<Observable<string>>(
  'A stream with the current language'
);

export const ROUTE_URL = new InjectionToken<Observable<string>>(
  'A stream with the route url'
);

export const CURRENT_ROUTED_CATEGORY_ID = new InjectionToken<Observable<string>>(
  'CURRENT_ROUTED_CATEGORY_ID'
);

export const ROUTE_SINGLE_CATEGORY = new InjectionToken<Observable<boolean>>(
  'ROUTE_SINGLE_CATEGORY'
);

export const ROUTE_PROVIDERS: Provider[] = [
  {
    provide: INITIAL_URL,
    deps: [Store],
    useFactory: initialUrlFactory
  },
  {
    provide: ROUTE_URL,
    deps: [Store],
    useFactory: routeUrlFactory
  },
  {
    provide: ROUTE_LANGUAGE,
    deps: [Store],
    useFactory: getRouteLanguageFactory
  },
  {
    provide: CURRENT_ROUTED_CONTENT,
    deps: [Store],
    useFactory: currentContentFactory
  },
  {
    provide: CURRENT_ROUTED_PAGE,
    deps: [CURRENT_ROUTED_CONTENT, Store],
    useFactory: currentStorePageFactory
  },
  {
    provide: CURRENT_ROUTED_PRODUCT,
    deps: [CURRENT_ROUTED_CONTENT, Store],
    useFactory: currentProductFactory
  },
  {
    provide: CURRENT_ROUTED_PRODUCT_ID,
    deps: [CURRENT_ROUTED_PRODUCT],
    useFactory: currentProductIdFactory
  },
  {
    provide: DEFAULT_PRODUCT_CATEGORY,
    deps: [Store, CURRENT_ROUTED_PRODUCT],
    useFactory: productDefaultCategoryFactory
  },
  {
    provide: CURRENT_ROUTED_CATEGORY_ID,
    deps: [Store],
    useFactory: currentCategoryIdFactory
  },
  {
    provide: CURRENT_ROUTED_CATEGORY,
    deps: [CURRENT_ROUTED_CATEGORY_ID, Store],
    useFactory: currentCategoryFactory
  },
  {
    provide: IS_HOME,
    deps: [Store, CURRENT_ROUTED_CONTENT],
    useFactory: isHomeFactory
  },
  {
    provide: IS_CATALOG_CONTEXT,
    deps: [IS_PRODUCT_CONTEXT, IS_INFORMATIONS_CONTEXT],
    useFactory: isCatalogContextFactory
  },
  {
    provide: IS_PRODUCT_CONTEXT,
    deps: [Store],
    useFactory: isProductContextFactory
  },
  {
    provide: IS_INFORMATIONS_CONTEXT,
    deps: [Store],
    useFactory: isInformationsContextFactory
  },
  {
    provide: LAST_NAVIGATION,
    deps: [Store],
    useFactory: lastNavigationFactory
  },
  {
    provide: GET_PATH_INFOS,
    deps: [Store],
    useFactory: getPathInfosFactory
  },
  {
    provide: ROUTE_SINGLE_CATEGORY,
    deps: [IS_XS, LOCAL_STORAGE],
    useFactory: routeSingleCategoryFactory
  }
];


export function routeSingleCategoryFactory(
  isXs$: Observable<boolean>,
  localStorage: Storage
): Observable<boolean> {
  const isSingleCategoryPref: boolean = JSON.parse(localStorage.getItem('isSingleCategory')) === true;
  return isXs$.pipe(
    map(isXs => {
      return isXs || isSingleCategoryPref || isScullyRunning();
    })
  );
}

export function getRouteLanguageFactory(
  store: Store<any>
): Observable<string> {
  return EMPTY;
}

export function getPathInfosFactory(
  store: Store<any>
): Observable<PathInfos> {
  return EMPTY;
}

export function routeUrlFactory(
  store: Store<any>
): Observable<string> {
  return EMPTY;
}

export function initialUrlFactory(
  store: Store<any>
): Observable<string> {
  return EMPTY;
}

export function isInformationsContextFactory(
  store: Store<any>
): Observable<boolean> {
  return EMPTY;
}

export function isProductContextFactory(
  store: Store
): Observable<boolean> {
  return store.pipe(
    select(selectRouteResourceIdentifier),
    map(resourceIdentifier => resourceIdentifier?.type === ResourceTypes.productDefault)
  );
}


export function isCatalogContextFactory(
  isProductContext$: Observable<boolean>,
  isInformationsContext$: Observable<boolean>
): Observable<boolean> {
  return EMPTY;
}

export function currentContentFactory(
  store: Store<any>
): Observable<ResourceIdentifier> {
  return store.pipe(select(selectRouteResourceIdentifier));
}

export function currentStorePageFactory(
  currentRoutedContent$: Observable<ResourceIdentifier>,
  store: Store<any>
): Observable<StorePage> {
  return currentRoutedContent$?.pipe(
    filter(content => content?.type === ResourceTypes.storePage),
    switchMap(content => store.pipe(select(getPageById(content.id))))
  );
}

export function currentProductFactory(
  currentRoutedContent$: Observable<ResourceIdentifier>,
  store: Store<any>
): Observable<Product> {
  return currentRoutedContent$?.pipe(
    switchMap(content => {
      if (content?.type === ResourceTypes.commerceProductDefault) {
        return store.pipe(select(selectProductById(content.id)));
      }
      return of(null);
    })
  );
}

export function currentProductIdFactory(
  currentProduct$: Observable<Product>,
  store: Store<any>
): Observable<string> {
  return currentProduct$?.pipe(
    map(currentProduct => currentProduct?.id)
  );
}

export function productDefaultCategoryFactory(
  store: Store<any>,
  currentProduct$: Observable<Product>
): Observable<Product> {
  return EMPTY;
}

export function currentCategoryIdFactory(
  store: Store<any>
): Observable<string> {
  return store.pipe(select(selectRouteCategoryId));
}

export function currentCategoryFactory(
  currentCategoryId$: Observable<string>,
  store: Store<any>
): Observable<Category> {
  return currentCategoryId$.pipe(
    switchMap(categoryId => store.pipe(select(selectCategoryById(categoryId)))),
    loadashObjectIsEqual()
  );
}

export function isHomeFactory(
  store: Store<any>,
  currentRoutedContent$: Observable<ResourceIdentifier>
): Observable<boolean> {
  return combineLatest([currentRoutedContent$, store.pipe(select(selectRouteHomeFragment))])?.pipe(
    map(([content, hasHomeFragment]) => {
      return content?.type === ResourceTypes.storeFront && hasHomeFragment;
    })
  );
}

export function lastNavigationFactory(
  store: Store<any>
): Observable<any> {
  return EMPTY;
}
