import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromWindow from './window.reducer';

export const selectWindowState = createFeatureSelector<fromWindow.State>(
  fromWindow.windowFeatureKey
);

export const isWindowOpened = (windowId: string) => createSelector(
  selectWindowState,
  (state: fromWindow.State) => {
    // console.log('state', state);
    return state && state.openIds.indexOf(windowId) > -1;
  }
);

