import {Inject, Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';
import {Image, ImageProfile} from '../model/image';
import {PRODUCT_DEFAULT_IMAGE} from '../providers/storeFront.providers';
import {Observable, of} from 'rxjs';
import {map} from "rxjs/operators";

@Pipe({
  name: 'resourceToImage'
})
export class ResourceToImagePipe implements PipeTransform {

  constructor(
    @Inject(PRODUCT_DEFAULT_IMAGE) private productDefaultImage$: Observable<Image>
  ) {
  }

  transform(resource: Resource): Observable<Image> {

    if (resource) {

      const {id, type} = resource;

      const profiles: { [profileId: string]: ImageProfile } = {};

      const links: any = resource.meta.imageDerivatives?.links || {};

      Object.keys(links).forEach(profileKey => {
        const projectsProfiles: string[] = [
          'medium',
          'style_w256',
          'style_w512',
          'style_w1024',
          'style_b128',
          'style_b256'
        ];
        if (profileKey !== 'self' && projectsProfiles.indexOf(profileKey) > -1) {
          const rawProfile: any = links[profileKey];
          profiles[profileKey] = {
            href: rawProfile.href
          };
        }
      });

      const image: Image = {
        id,
        type,
        profiles
      };

      return of(image);

    }

    return this.productDefaultImage$.pipe(
      map(defaultImage => {
        if (defaultImage) {
          return defaultImage;
        }
        return null;
      })
    );

  }

}
