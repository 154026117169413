import {ResourceDefinition} from 'ngrx-json-api';

export enum ResourceTypes {
  commerceOrderDefault = 'commerce_order--default',
  file = 'file--file',
  mediaImage = 'media--image',
  account = 'group--account',
  storeFront = 'node--store_front',
  productDefault = 'commerce_product--default',
  productVariationDefault = 'product-variation--default',
  taxonomyTermCategories = 'taxonomy_term--categories',
  profileCustomer = 'profile--customer',
  storePage = 'node--store_page',
  storeSection = 'node--store_section',
  storeMessage = 'node--store_message',
  commerceProductDefault = 'commerce_product--default',
  commerceProductVariationDefault = 'commerce_product_variation--default',
  paragraphPageLocation = 'paragraph--page_location',
  paragraphPageVideo = 'paragraph--page_video',
  paragraphPageText = 'paragraph--page_text',
  paragraphPagePrivacy = 'paragraph--page_privacy',
  paragraphPageImages = 'paragraph--page_images',
  paragraphPageNewsletterSubscription = 'paragraph--page_newsletter_subscription',
  paragraphPageProducts = 'paragraph--page_products'
}


export class NgrxJsonApiDefinitions {

  static readonly resourceDefinitions: Array<ResourceDefinition> = [
    {
      type: ResourceTypes.commerceOrderDefault,
      collectionPath: 'checkout',
      attributes: {
        name: {},
        uri: {},
      },
    },
    {
      type: ResourceTypes.file,
      collectionPath: 'file/file',
      attributes: {
        name: {},
        uri: {},
      },
    },
    {
      type: ResourceTypes.mediaImage,
      collectionPath: 'media/image',
      attributes: {
        name: {},
      },
      relationships: {
        field_media_image: {
          relationType: 'hasOne',
          type: 'file--file',
        },
      }
    },
    {
      type: ResourceTypes.account,
      collectionPath: 'group/account',
      attributes: {
        title: {},
        body: {},
      },
      relationships: {
        field_store_front: {
          relationType: 'hasOne',
          type: ResourceTypes.storeFront,
        },
      }
    },
    {
      type: ResourceTypes.storeFront,
      collectionPath: 'node/store_front',
      attributes: {
        title: {},
        body: {},
      },
      relationships: {
        field_image: {
          relationType: 'hasOne',
          type: 'media--image',
        },
      }
    },
    {
      type: ResourceTypes.storeSection,
      collectionPath: 'node/store_section',
      attributes: {
        title: {},
        body: {},
      },
      relationships: {}
    },
    {
      type: ResourceTypes.storeMessage,
      collectionPath: 'node/store_message'
    },
    {
      type: ResourceTypes.productDefault,
      collectionPath: 'commerce_product/default',
      attributes: {
        status: {},
        title: {},
      },
      relationships: {
        field_categories: {
          relationType: 'hasMany',
          type: 'taxonomy_term--categories',
        },
        field_icon: {
          relationType: 'hasOne',
          type: 'media--image',
        },
        field_image: {
          relationType: 'hasOne',
          type: 'media--image',
        },
        field_sections: {
          relationType: 'hasMany',
          type: 'node--store_section',
        },
        variations: {
          relationType: 'hasMany',
          type: 'product-variation--default',
        }
      }
    },
    {
      type: ResourceTypes.productVariationDefault,
      collectionPath: 'commerce_product_variation/default',
      attributes: {
        status: {},
        sku: {},
        title: {},
        list_price: {},
        price: {},
        resolved_price: {}
      },
    },
    {
      type: ResourceTypes.taxonomyTermCategories,
      collectionPath: 'taxonomy_term/categories',
      attributes: {
        name: {}
      },
    },
    {
      type: ResourceTypes.profileCustomer,
      collectionPath: 'profile/customer'
    },
    {
      type: ResourceTypes.storePage,
      collectionPath: 'node/store_page'
    }
  ];

}
